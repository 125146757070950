/// <reference path="./custom.d.ts" />
// tslint:disable
/**
 * Unika API
 * Insurance management API
 *
 * OpenAPI spec version: 2025-01-19 12:12
 * Contact: kundservice@unikaforsakringar.se
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import * as portableFetch from 'portable-fetch';
import * as url from 'url';
import { Configuration } from './configuration';

const BASE_PATH = 'http://localhost:3000'.replace(/\/+$/, '');

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
  csv: ',',
  ssv: ' ',
  tsv: '\t',
  pipes: '|',
};

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
  (url: string, init?: any): Promise<Response>;
}

/**
 *
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
  url: string;
  options: any;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
  protected configuration?: Configuration;

  constructor(
    configuration?: Configuration,
    protected basePath: string = BASE_PATH,
    protected fetch: FetchAPI = portableFetch,
  ) {
    if (configuration) {
      this.configuration = configuration;
      this.basePath = configuration.basePath || this.basePath;
    }
  }
}

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
  name = 'RequiredError';
  constructor(public field: string, msg?: string) {
    super(msg);
  }
}

/**
 *
 * @export
 * @interface Account
 */
export interface Account {
  /**
   *
   * @type {AccountBroker}
   * @memberof Account
   */
  broker?: AccountBroker;
  /**
   *
   * @type {Array<Broker>}
   * @memberof Account
   */
  brokers?: Array<Broker>;
  /**
   *
   * @type {Array<Account>}
   * @memberof Account
   */
  companies?: Array<Account>;
  /**
   *
   * @type {Date}
   * @memberof Account
   */
  created?: Date;
  /**
   *
   * @type {boolean}
   * @memberof Account
   */
  demo: boolean;
  /**
   *
   * @type {number}
   * @memberof Account
   */
  discount?: number;
  /**
   *
   * @type {string}
   * @memberof Account
   */
  id: string;
  /**
   *
   * @type {Array<Order>}
   * @memberof Account
   */
  orders?: Array<Order>;
  /**
   *
   * @type {Array<PaymentMethod>}
   * @memberof Account
   */
  paymentMethods?: Array<PaymentMethod>;
  /**
   *
   * @type {Array<Product>}
   * @memberof Account
   */
  products?: Array<Product>;
  /**
   *
   * @type {Sensitive}
   * @memberof Account
   */
  sensitive?: Sensitive;
  /**
   *
   * @type {string}
   * @memberof Account
   */
  status: Account.StatusEnum;
  /**
   *
   * @type {string}
   * @memberof Account
   */
  token?: string;
  /**
   *
   * @type {string}
   * @memberof Account
   */
  type: Account.TypeEnum;
  /**
   *
   * @type {Date}
   * @memberof Account
   */
  updated?: Date;
}

/**
 * @export
 * @namespace Account
 */
export namespace Account {
  /**
   * @export
   * @enum {string}
   */
  export enum StatusEnum {
    Active = 'active',
    Deleting = 'deleting',
    Deleted = 'deleted',
  }
  /**
   * @export
   * @enum {string}
   */
  export enum TypeEnum {
    Person = 'person',
    Company = 'company',
  }
}

/**
 *
 * @export
 * @interface AccountBroker
 */
export interface AccountBroker {}

/**
 *
 * @export
 * @interface AccountSwitchResponse
 */
export interface AccountSwitchResponse {
  /**
   *
   * @type {string}
   * @memberof AccountSwitchResponse
   */
  token?: string;
}

/**
 *
 * @export
 * @interface AccountsReportPayload
 */
export interface AccountsReportPayload {
  /**
   *
   * @type {string}
   * @memberof AccountsReportPayload
   */
  annuityLogic?: AccountsReportPayload.AnnuityLogicEnum;
  /**
   *
   * @type {string}
   * @memberof AccountsReportPayload
   */
  broker?: string;
  /**
   *
   * @type {string}
   * @memberof AccountsReportPayload
   */
  endDate?: string;
  /**
   *
   * @type {boolean}
   * @memberof AccountsReportPayload
   */
  isUnderwriting?: boolean;
  /**
   *
   * @type {string}
   * @memberof AccountsReportPayload
   */
  partner?: AccountsReportPayload.PartnerEnum;
  /**
   *
   * @type {Array<string>}
   * @memberof AccountsReportPayload
   */
  productTypes?: Array<AccountsReportPayload.ProductTypesEnum>;
  /**
   *
   * @type {string}
   * @memberof AccountsReportPayload
   */
  simulatePricing?: string;
  /**
   *
   * @type {string}
   * @memberof AccountsReportPayload
   */
  startDate?: string;
  /**
   *
   * @type {string}
   * @memberof AccountsReportPayload
   */
  type: AccountsReportPayload.TypeEnum;
}

/**
 * @export
 * @namespace AccountsReportPayload
 */
export namespace AccountsReportPayload {
  /**
   * @export
   * @enum {string}
   */
  export enum AnnuityLogicEnum {
    V1 = 'v1',
    V2 = 'v2',
  }
  /**
   * @export
   * @enum {string}
   */
  export enum PartnerEnum {
    Axis = 'axis',
    Aiu = 'aiu',
    Axeria = 'axeria',
  }
  /**
   * @export
   * @enum {string}
   */
  export enum ProductTypesEnum {
    HealthPlus = 'health_plus',
    Health = 'health',
    HealthCheckup = 'health_checkup',
  }
  /**
   * @export
   * @enum {string}
   */
  export enum TypeEnum {
    Written = 'written',
    Paid = 'paid',
    PaidBdx = 'paidBdx',
    Renewed = 'renewed',
    WrittenAndRenewed = 'writtenAndRenewed',
  }
}

/**
 *
 * @export
 * @interface AccountsReportRow
 */
export interface AccountsReportRow {
  /**
   *
   * @type {boolean}
   * @memberof AccountsReportRow
   */
  addedAnnuity?: boolean;
  /**
   *
   * @type {number}
   * @memberof AccountsReportRow
   */
  age?: number;
  /**
   *
   * @type {number}
   * @memberof AccountsReportRow
   */
  amount: number;
  /**
   *
   * @type {number}
   * @memberof AccountsReportRow
   */
  annualPrice: number;
  /**
   *
   * @type {string}
   * @memberof AccountsReportRow
   */
  birthday: string;
  /**
   *
   * @type {number}
   * @memberof AccountsReportRow
   */
  brain?: number;
  /**
   *
   * @type {string}
   * @memberof AccountsReportRow
   */
  broker?: string;
  /**
   *
   * @type {number}
   * @memberof AccountsReportRow
   */
  brokerCommission: number;
  /**
   *
   * @type {number}
   * @memberof AccountsReportRow
   */
  brokerCommissionVAT: number;
  /**
   *
   * @type {string}
   * @memberof AccountsReportRow
   */
  brokerCompanyName?: string;
  /**
   *
   * @type {string}
   * @memberof AccountsReportRow
   */
  brokerName?: string;
  /**
   *
   * @type {number}
   * @memberof AccountsReportRow
   */
  brokerOrgCommission: number;
  /**
   *
   * @type {number}
   * @memberof AccountsReportRow
   */
  brokerOrgCommissionVAT: number;
  /**
   *
   * @type {string}
   * @memberof AccountsReportRow
   */
  cancellationDate?: string;
  /**
   *
   * @type {number}
   * @memberof AccountsReportRow
   */
  cancer?: number;
  /**
   *
   * @type {number}
   * @memberof AccountsReportRow
   */
  chronic?: number;
  /**
   *
   * @type {string}
   * @memberof AccountsReportRow
   */
  created?: string;
  /**
   *
   * @type {number}
   * @memberof AccountsReportRow
   */
  diabetes?: number;
  /**
   *
   * @type {number}
   * @memberof AccountsReportRow
   */
  discount: number;
  /**
   *
   * @type {number}
   * @memberof AccountsReportRow
   */
  examinations?: number;
  /**
   *
   * @type {any}
   * @memberof AccountsReportRow
   */
  frontingFees?: any;
  /**
   *
   * @type {string}
   * @memberof AccountsReportRow
   */
  fullName: string;
  /**
   *
   * @type {any}
   * @memberof AccountsReportRow
   */
  furtherCommission: any;
  /**
   *
   * @type {string}
   * @memberof AccountsReportRow
   */
  gender?: AccountsReportRow.GenderEnum;
  /**
   *
   * @type {string}
   * @memberof AccountsReportRow
   */
  groupPolicyType?: AccountsReportRow.GroupPolicyTypeEnum;
  /**
   *
   * @type {number}
   * @memberof AccountsReportRow
   */
  heart?: number;
  /**
   *
   * @type {string}
   * @memberof AccountsReportRow
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof AccountsReportRow
   */
  inGroup?: AccountsReportRow.InGroupEnum;
  /**
   *
   * @type {number}
   * @memberof AccountsReportRow
   */
  index: number;
  /**
   *
   * @type {string}
   * @memberof AccountsReportRow
   */
  initialDecision?: string;
  /**
   *
   * @type {string}
   * @memberof AccountsReportRow
   */
  insuredFrom: string;
  /**
   *
   * @type {boolean}
   * @memberof AccountsReportRow
   */
  isMain: boolean;
  /**
   *
   * @type {number}
   * @memberof AccountsReportRow
   */
  leukemia?: number;
  /**
   *
   * @type {number}
   * @memberof AccountsReportRow
   */
  livesCount: number;
  /**
   *
   * @type {string}
   * @memberof AccountsReportRow
   */
  locality: string;
  /**
   *
   * @type {any}
   * @memberof AccountsReportRow
   */
  londonCommission: any;
  /**
   *
   * @type {string}
   * @memberof AccountsReportRow
   */
  order: string;
  /**
   *
   * @type {string}
   * @memberof AccountsReportRow
   */
  orderType: string;
  /**
   *
   * @type {number}
   * @memberof AccountsReportRow
   */
  originalPrice?: number;
  /**
   *
   * @type {string}
   * @memberof AccountsReportRow
   */
  owner: string;
  /**
   *
   * @type {string}
   * @memberof AccountsReportRow
   */
  paidDate?: string;
  /**
   *
   * @type {string}
   * @memberof AccountsReportRow
   */
  paidFrom: string;
  /**
   *
   * @type {string}
   * @memberof AccountsReportRow
   */
  paidUntil: string;
  /**
   *
   * @type {string}
   * @memberof AccountsReportRow
   */
  payer: string;
  /**
   *
   * @type {number}
   * @memberof AccountsReportRow
   */
  payerActiveOrders?: number;
  /**
   *
   * @type {string}
   * @memberof AccountsReportRow
   */
  payerAnnuityDate?: string;
  /**
   *
   * @type {string}
   * @memberof AccountsReportRow
   */
  payerName?: string;
  /**
   *
   * @type {string}
   * @memberof AccountsReportRow
   */
  payerType?: AccountsReportRow.PayerTypeEnum;
  /**
   *
   * @type {number}
   * @memberof AccountsReportRow
   */
  paymentPeriod?: number;
  /**
   *
   * @type {number}
   * @memberof AccountsReportRow
   */
  pendingTransactionsCount: number;
  /**
   *
   * @type {string}
   * @memberof AccountsReportRow
   */
  personnummer: string;
  /**
   *
   * @type {string}
   * @memberof AccountsReportRow
   */
  policyholderRelationship?: AccountsReportRow.PolicyholderRelationshipEnum;
  /**
   *
   * @type {string}
   * @memberof AccountsReportRow
   */
  postalCode: string;
  /**
   *
   * @type {number}
   * @memberof AccountsReportRow
   */
  potentialCancer?: number;
  /**
   *
   * @type {number}
   * @memberof AccountsReportRow
   */
  price: number;
  /**
   *
   * @type {string}
   * @memberof AccountsReportRow
   */
  productName?: string;
  /**
   *
   * @type {any}
   * @memberof AccountsReportRow
   */
  reinsuranceBrokerage?: any;
  /**
   *
   * @type {string}
   * @memberof AccountsReportRow
   */
  relationship?: AccountsReportRow.RelationshipEnum;
  /**
   *
   * @type {boolean}
   * @memberof AccountsReportRow
   */
  removedAnnuity?: boolean;
  /**
   *
   * @type {string}
   * @memberof AccountsReportRow
   */
  riskFrom: string;
  /**
   *
   * @type {any}
   * @memberof AccountsReportRow
   */
  riskPremium?: any;
  /**
   *
   * @type {string}
   * @memberof AccountsReportRow
   */
  riskUntil: string;
  /**
   *
   * @type {string}
   * @memberof AccountsReportRow
   */
  streetAddress: string;
  /**
   *
   * @type {any}
   * @memberof AccountsReportRow
   */
  tiedAgentCommission?: any;
  /**
   *
   * @type {number}
   * @memberof AccountsReportRow
   */
  totalBrokerCommission: number;
  /**
   *
   * @type {string}
   * @memberof AccountsReportRow
   */
  transactionType: AccountsReportRow.TransactionTypeEnum;
  /**
   *
   * @type {number}
   * @memberof AccountsReportRow
   */
  transactionsCount: number;
  /**
   *
   * @type {number}
   * @memberof AccountsReportRow
   */
  tumor?: number;
  /**
   *
   * @type {number}
   * @memberof AccountsReportRow
   */
  unikaRevenue: number;
}

/**
 * @export
 * @namespace AccountsReportRow
 */
export namespace AccountsReportRow {
  /**
   * @export
   * @enum {string}
   */
  export enum GenderEnum {
    Male = 'Male',
    Female = 'Female',
  }
  /**
   * @export
   * @enum {string}
   */
  export enum GroupPolicyTypeEnum {
    Single = 'single',
    SingleGroup = 'single_group',
    CoupleGroup = 'couple_group',
    SingleChildGroup = 'single_child_group',
    FamilyGroup = 'family_group',
  }
  /**
   * @export
   * @enum {string}
   */
  export enum InGroupEnum {
    Group = 'Group',
    NoGroup = 'No Group',
  }
  /**
   * @export
   * @enum {string}
   */
  export enum PayerTypeEnum {
    Written = 'written',
    Paid = 'paid',
    PaidBdx = 'paidBdx',
    Renewed = 'renewed',
    WrittenAndRenewed = 'writtenAndRenewed',
  }
  /**
   * @export
   * @enum {string}
   */
  export enum PolicyholderRelationshipEnum {
    A = 'A',
    B = 'B',
    C = 'C',
    D = 'D',
  }
  /**
   * @export
   * @enum {string}
   */
  export enum RelationshipEnum {
    Main = 'main',
    Spouse = 'spouse',
    Child = 'child',
  }
  /**
   * @export
   * @enum {string}
   */
  export enum TransactionTypeEnum {
    OP = 'OP',
    AP = 'AP',
    RP = 'RP',
    CANCEL = 'CANCEL',
  }
}

/**
 *
 * @export
 * @interface AuthPayload
 */
export interface AuthPayload {
  /**
   *
   * @type {string}
   * @memberof AuthPayload
   */
  personnummer?: string;
}

/**
 *
 * @export
 * @interface AuthRequest
 */
export interface AuthRequest {
  /**
   *
   * @type {string}
   * @memberof AuthRequest
   */
  autoStartToken?: string;
  /**
   *
   * @type {Date}
   * @memberof AuthRequest
   */
  created?: Date;
  /**
   *
   * @type {string}
   * @memberof AuthRequest
   */
  error?: AuthRequest.ErrorEnum;
  /**
   *
   * @type {string}
   * @memberof AuthRequest
   */
  hint?: AuthRequest.HintEnum;
  /**
   *
   * @type {string}
   * @memberof AuthRequest
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof AuthRequest
   */
  qr?: string;
  /**
   *
   * @type {string}
   * @memberof AuthRequest
   */
  status: AuthRequest.StatusEnum;
  /**
   *
   * @type {string}
   * @memberof AuthRequest
   */
  token?: string;
  /**
   *
   * @type {Date}
   * @memberof AuthRequest
   */
  updated?: Date;
}

/**
 * @export
 * @namespace AuthRequest
 */
export namespace AuthRequest {
  /**
   * @export
   * @enum {string}
   */
  export enum ErrorEnum {
    Cancelled = 'cancelled',
    AlreadyInProgress = 'alreadyInProgress',
    RequestTimeout = 'requestTimeout',
    Maintenance = 'maintenance',
    InternalError = 'internalError',
  }
  /**
   * @export
   * @enum {string}
   */
  export enum HintEnum {
    OutstandingTransaction = 'outstandingTransaction',
    NoClient = 'noClient',
    UserCancel = 'userCancel',
    ExpiredTransaction = 'expiredTransaction',
    UserSign = 'userSign',
    Started = 'started',
    CertificateErr = 'certificateErr',
    StartFailed = 'startFailed',
  }
  /**
   * @export
   * @enum {string}
   */
  export enum StatusEnum {
    Pending = 'pending',
    Failed = 'failed',
    Complete = 'complete',
  }
}

/**
 *
 * @export
 * @interface BankRequest
 */
export interface BankRequest {
  /**
   *
   * @type {Array<BankRequestAccount>}
   * @memberof BankRequest
   */
  accounts?: Array<BankRequestAccount>;
  /**
   *
   * @type {string}
   * @memberof BankRequest
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof BankRequest
   */
  qr?: string;
  /**
   *
   * @type {string}
   * @memberof BankRequest
   */
  status: BankRequest.StatusEnum;
  /**
   *
   * @type {string}
   * @memberof BankRequest
   */
  token?: string;
}

/**
 * @export
 * @namespace BankRequest
 */
export namespace BankRequest {
  /**
   * @export
   * @enum {string}
   */
  export enum StatusEnum {
    Waiting = 'Waiting',
    Success = 'Success',
    Failed = 'Failed',
  }
}

/**
 *
 * @export
 * @interface BankRequestAccount
 */
export interface BankRequestAccount {
  /**
   *
   * @type {string}
   * @memberof BankRequestAccount
   */
  account: string;
  /**
   *
   * @type {boolean}
   * @memberof BankRequestAccount
   */
  autogiro: boolean;
  /**
   *
   * @type {string}
   * @memberof BankRequestAccount
   */
  clearing: string;
  /**
   *
   * @type {string}
   * @memberof BankRequestAccount
   */
  name: string;
}

/**
 *
 * @export
 * @interface BankRequestPayload
 */
export interface BankRequestPayload {
  /**
   *
   * @type {string}
   * @memberof BankRequestPayload
   */
  bank: BankRequestPayload.BankEnum;
  /**
   *
   * @type {string}
   * @memberof BankRequestPayload
   */
  personnummer: string;
}

/**
 * @export
 * @namespace BankRequestPayload
 */
export namespace BankRequestPayload {
  /**
   * @export
   * @enum {string}
   */
  export enum BankEnum {
    OEB = 'OEB',
    SHB = 'SHB',
    ICA = 'ICA',
    LFB = 'LFB',
    NB = 'NB',
    SBAB = 'SBAB',
    SEB = 'SEB',
    SKB = 'SKB',
    SYD = 'SYD',
    FSPA = 'FSPA',
    FSPASB = 'FSPASB',
  }
}

/**
 *
 * @export
 * @interface Broker
 */
export interface Broker {
  /**
   *
   * @type {Array<Account>}
   * @memberof Broker
   */
  accounts?: Array<Account>;
  /**
   *
   * @type {string}
   * @memberof Broker
   */
  bankgiro?: string;
  /**
   *
   * @type {any}
   * @memberof Broker
   */
  brokerOrgCommissionOverride: any;
  /**
   *
   * @type {Array<Broker>}
   * @memberof Broker
   */
  children?: Array<Broker>;
  /**
   *
   * @type {any}
   * @memberof Broker
   */
  code: any;
  /**
   *
   * @type {string}
   * @memberof Broker
   */
  color?: string;
  /**
   *
   * @type {number}
   * @memberof Broker
   */
  commission: number;
  /**
   *
   * @type {Account}
   * @memberof Broker
   */
  company?: Account;
  /**
   *
   * @type {Date}
   * @memberof Broker
   */
  created?: Date;
  /**
   *
   * @type {Array<string>}
   * @memberof Broker
   */
  extraPricings?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof Broker
   */
  healthCheckupCommission: number;
  /**
   *
   * @type {string}
   * @memberof Broker
   */
  id: string;
  /**
   *
   * @type {boolean}
   * @memberof Broker
   */
  isOrg?: boolean;
  /**
   *
   * @type {string}
   * @memberof Broker
   */
  name: string;
  /**
   *
   * @type {Array<BrokerNotification>}
   * @memberof Broker
   */
  notifications?: Array<BrokerNotification>;
  /**
   *
   * @type {any}
   * @memberof Broker
   */
  oneTimeCashBonus: any;
  /**
   *
   * @type {Array<Account>}
   * @memberof Broker
   */
  owners?: Array<Account>;
  /**
   *
   * @type {Broker}
   * @memberof Broker
   */
  parent?: Broker;
  /**
   *
   * @type {Pricing}
   * @memberof Broker
   */
  pricing?: Pricing;
  /**
   *
   * @type {Date}
   * @memberof Broker
   */
  updated?: Date;
  /**
   *
   * @type {any}
   * @memberof Broker
   */
  utm?: any;
}

/**
 *
 * @export
 * @interface BrokerCustomers
 */
export interface BrokerCustomers {
  /**
   *
   * @type {Broker}
   * @memberof BrokerCustomers
   */
  broker?: Broker;
  /**
   *
   * @type {Array<BrokerCustomersPayer>}
   * @memberof BrokerCustomers
   */
  payers: Array<BrokerCustomersPayer>;
}

/**
 *
 * @export
 * @interface BrokerCustomersPayer
 */
export interface BrokerCustomersPayer {
  /**
   *
   * @type {Array<Order>}
   * @memberof BrokerCustomersPayer
   */
  orders: Array<Order>;
  /**
   *
   * @type {Account}
   * @memberof BrokerCustomersPayer
   */
  payer: Account;
}

/**
 *
 * @export
 * @interface BrokerInvite
 */
export interface BrokerInvite {
  /**
   *
   * @type {string}
   * @memberof BrokerInvite
   */
  token: string;
}

/**
 *
 * @export
 * @interface BrokerInvitePayload
 */
export interface BrokerInvitePayload {
  /**
   *
   * @type {string}
   * @memberof BrokerInvitePayload
   */
  brokerId?: string;
  /**
   *
   * @type {string}
   * @memberof BrokerInvitePayload
   */
  brokerOrg?: string;
  /**
   *
   * @type {number}
   * @memberof BrokerInvitePayload
   */
  commission: number;
  /**
   *
   * @type {Array<string>}
   * @memberof BrokerInvitePayload
   */
  extraPricings?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof BrokerInvitePayload
   */
  healthCheckupCommission: number;
  /**
   *
   * @type {string}
   * @memberof BrokerInvitePayload
   */
  pricing?: string;
}

/**
 *
 * @export
 * @interface BrokerMRR
 */
export interface BrokerMRR {
  /**
   *
   * @type {string}
   * @memberof BrokerMRR
   */
  brokerId?: string;
  /**
   *
   * @type {string}
   * @memberof BrokerMRR
   */
  date: string;
  /**
   *
   * @type {number}
   * @memberof BrokerMRR
   */
  lives: number;
  /**
   *
   * @type {number}
   * @memberof BrokerMRR
   */
  mrr: number;
  /**
   *
   * @type {number}
   * @memberof BrokerMRR
   */
  orders: number;
}

/**
 *
 * @export
 * @interface BrokerNotification
 */
export interface BrokerNotification {
  /**
   *
   * @type {string}
   * @memberof BrokerNotification
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof BrokerNotification
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof BrokerNotification
   */
  type: BrokerNotification.TypeEnum;
}

/**
 * @export
 * @namespace BrokerNotification
 */
export namespace BrokerNotification {
  /**
   * @export
   * @enum {string}
   */
  export enum TypeEnum {
    Order = 'order',
    Report = 'report',
    Mislife = 'mislife',
  }
}

/**
 *
 * @export
 * @interface BrokerPayload
 */
export interface BrokerPayload {
  /**
   *
   * @type {string}
   * @memberof BrokerPayload
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof BrokerPayload
   */
  orgnummer: string;
  /**
   *
   * @type {string}
   * @memberof BrokerPayload
   */
  personnummer: string;
  /**
   *
   * @type {string}
   * @memberof BrokerPayload
   */
  token?: string;
}

/**
 *
 * @export
 * @interface BrokerToAccountPayload
 */
export interface BrokerToAccountPayload {
  /**
   *
   * @type {string}
   * @memberof BrokerToAccountPayload
   */
  accountId: string;
  /**
   *
   * @type {string}
   * @memberof BrokerToAccountPayload
   */
  brokerId?: string;
}

/**
 *
 * @export
 * @interface ChangePayerPayload
 */
export interface ChangePayerPayload {
  /**
   *
   * @type {string}
   * @memberof ChangePayerPayload
   */
  orderId: string;
  /**
   *
   * @type {string}
   * @memberof ChangePayerPayload
   */
  personnummer: string;
}

/**
 *
 * @export
 * @interface ConnectPaymentResponse
 */
export interface ConnectPaymentResponse {
  /**
   *
   * @type {string}
   * @memberof ConnectPaymentResponse
   */
  id?: string;
  /**
   *
   * @type {Account}
   * @memberof ConnectPaymentResponse
   */
  payer?: Account;
}

/**
 *
 * @export
 * @interface InlineResponse400
 */
export interface InlineResponse400 {
  /**
   *
   * @type {string}
   * @memberof InlineResponse400
   */
  error?: string;
  /**
   *
   * @type {string}
   * @memberof InlineResponse400
   */
  message: string;
  /**
   *
   * @type {number}
   * @memberof InlineResponse400
   */
  statusCode: number;
}

/**
 *
 * @export
 * @interface Lab
 */
export interface Lab {
  /**
   *
   * @type {string}
   * @memberof Lab
   */
  address: string;
  /**
   *
   * @type {string}
   * @memberof Lab
   */
  id: string;
  /**
   *
   * @type {number}
   * @memberof Lab
   */
  lat: number;
  /**
   *
   * @type {number}
   * @memberof Lab
   */
  lng: number;
  /**
   *
   * @type {string}
   * @memberof Lab
   */
  locality: string;
  /**
   *
   * @type {string}
   * @memberof Lab
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof Lab
   */
  url: string;
}

/**
 *
 * @export
 * @interface MetricsPayload
 */
export interface MetricsPayload {
  /**
   *
   * @type {string}
   * @memberof MetricsPayload
   */
  productType?: MetricsPayload.ProductTypeEnum;
}

/**
 * @export
 * @namespace MetricsPayload
 */
export namespace MetricsPayload {
  /**
   * @export
   * @enum {string}
   */
  export enum ProductTypeEnum {
    Health = 'health',
    HealthCheckup = 'health_checkup',
  }
}

/**
 *
 * @export
 * @interface MislifePayload
 */
export interface MislifePayload {
  /**
   *
   * @type {Array<string>}
   * @memberof MislifePayload
   */
  productTypes?: Array<MislifePayload.ProductTypesEnum>;
}

/**
 * @export
 * @namespace MislifePayload
 */
export namespace MislifePayload {
  /**
   * @export
   * @enum {string}
   */
  export enum ProductTypesEnum {
    HealthPlus = 'health_plus',
    Health = 'health',
    HealthCheckup = 'health_checkup',
  }
}

/**
 *
 * @export
 * @interface ModelObject
 */
export interface ModelObject {}

/**
 *
 * @export
 * @interface Offer
 */
export interface Offer {
  /**
   *
   * @type {Account}
   * @memberof Offer
   */
  account?: Account;
  /**
   *
   * @type {Broker}
   * @memberof Offer
   */
  broker?: Broker;
  /**
   *
   * @type {Date}
   * @memberof Offer
   */
  created?: Date;
  /**
   *
   * @type {string}
   * @memberof Offer
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof Offer
   */
  id?: string;
  /**
   *
   * @type {OrderPayload}
   * @memberof Offer
   */
  json?: OrderPayload;
  /**
   *
   * @type {AccountBroker}
   * @memberof Offer
   */
  order?: AccountBroker;
  /**
   *
   * @type {string}
   * @memberof Offer
   */
  personnummer?: string;
  /**
   *
   * @type {string}
   * @memberof Offer
   */
  phone?: string;
  /**
   *
   * @type {Pricing}
   * @memberof Offer
   */
  pricing?: Pricing;
  /**
   *
   * @type {Date}
   * @memberof Offer
   */
  sent?: Date;
  /**
   *
   * @type {string}
   * @memberof Offer
   */
  status: Offer.StatusEnum;
  /**
   *
   * @type {Date}
   * @memberof Offer
   */
  updated?: Date;
}

/**
 * @export
 * @namespace Offer
 */
export namespace Offer {
  /**
   * @export
   * @enum {string}
   */
  export enum StatusEnum {
    New = 'new',
    Sent = 'sent',
    Viewed = 'viewed',
    PersonsAdded = 'persons_added',
    PaymentAdded = 'payment_added',
    ConsentGiven = 'consent_given',
    OrderCreated = 'order_created',
    OrderCancelled = 'order_cancelled',
  }
}

/**
 *
 * @export
 * @interface Order
 */
export interface Order {
  /**
   *
   * @type {Date}
   * @memberof Order
   */
  created?: Date;
  /**
   *
   * @type {Date}
   * @memberof Order
   */
  firstPaidFrom?: Date;
  /**
   *
   * @type {string}
   * @memberof Order
   */
  id: string;
  /**
   *
   * @type {any}
   * @memberof Order
   */
  invoiceReference?: any;
  /**
   *
   * @type {Date}
   * @memberof Order
   */
  lastPaidUntil?: Date;
  /**
   *
   * @type {Pricing}
   * @memberof Order
   */
  mainPricing?: Pricing;
  /**
   *
   * @type {Account}
   * @memberof Order
   */
  owner: Account;
  /**
   *
   * @type {Account}
   * @memberof Order
   */
  payer: Account;
  /**
   *
   * @type {number}
   * @memberof Order
   */
  paymentPeriod?: number;
  /**
   *
   * @type {number}
   * @memberof Order
   */
  price?: number;
  /**
   *
   * @type {ProductPricing}
   * @memberof Order
   */
  pricing?: ProductPricing;
  /**
   *
   * @type {Array<Product>}
   * @memberof Order
   */
  products: Array<Product>;
  /**
   *
   * @type {Array<PaymentTransaction>}
   * @memberof Order
   */
  transactions: Array<PaymentTransaction>;
  /**
   *
   * @type {string}
   * @memberof Order
   */
  type?: Order.TypeEnum;
  /**
   *
   * @type {Date}
   * @memberof Order
   */
  updated?: Date;
}

/**
 * @export
 * @namespace Order
 */
export namespace Order {
  /**
   * @export
   * @enum {string}
   */
  export enum TypeEnum {
    Single = 'single',
    Couple = 'couple',
    SingleChild = 'single_child',
    Family = 'family',
  }
}

/**
 *
 * @export
 * @interface OrderOptionsPayload
 */
export interface OrderOptionsPayload {
  /**
   *
   * @type {string}
   * @memberof OrderOptionsPayload
   */
  invoiceReference?: string;
  /**
   *
   * @type {string}
   * @memberof OrderOptionsPayload
   */
  payer?: string;
  /**
   *
   * @type {number}
   * @memberof OrderOptionsPayload
   */
  paymentPeriod?: number;
}

/**
 *
 * @export
 * @interface OrderPayload
 */
export interface OrderPayload {
  /**
   *
   * @type {string}
   * @memberof OrderPayload
   */
  brokerId?: string;
  /**
   *
   * @type {boolean}
   * @memberof OrderPayload
   */
  consent?: boolean;
  /**
   *
   * @type {ProductMetaData}
   * @memberof OrderPayload
   */
  meta?: ProductMetaData;
  /**
   *
   * @type {string}
   * @memberof OrderPayload
   */
  offerId?: string;
  /**
   *
   * @type {PaymentPayload}
   * @memberof OrderPayload
   */
  paymentMethod?: PaymentPayload;
  /**
   *
   * @type {number}
   * @memberof OrderPayload
   */
  paymentPeriod?: number;
  /**
   *
   * @type {Array<Person>}
   * @memberof OrderPayload
   */
  persons: Array<Person>;
  /**
   *
   * @type {string}
   * @memberof OrderPayload
   */
  pricingId?: string;
  /**
   *
   * @type {string}
   * @memberof OrderPayload
   */
  productType?: OrderPayload.ProductTypeEnum;
  /**
   *
   * @type {string}
   * @memberof OrderPayload
   */
  startDate?: string;
}

/**
 * @export
 * @namespace OrderPayload
 */
export namespace OrderPayload {
  /**
   * @export
   * @enum {string}
   */
  export enum ProductTypeEnum {
    HealthPlus = 'health_plus',
    Health = 'health',
    HealthCheckup = 'health_checkup',
  }
}

/**
 *
 * @export
 * @interface PaymentMethod
 */
export interface PaymentMethod {
  /**
   *
   * @type {Date}
   * @memberof PaymentMethod
   */
  created?: Date;
  /**
   *
   * @type {string}
   * @memberof PaymentMethod
   */
  gateway: PaymentMethod.GatewayEnum;
  /**
   *
   * @type {string}
   * @memberof PaymentMethod
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof PaymentMethod
   */
  method: string;
  /**
   *
   * @type {string}
   * @memberof PaymentMethod
   */
  methodId: string;
  /**
   *
   * @type {string}
   * @memberof PaymentMethod
   */
  status: PaymentMethod.StatusEnum;
  /**
   *
   * @type {Array<PaymentTransaction>}
   * @memberof PaymentMethod
   */
  transactions: Array<PaymentTransaction>;
  /**
   *
   * @type {Date}
   * @memberof PaymentMethod
   */
  updated?: Date;
}

/**
 * @export
 * @namespace PaymentMethod
 */
export namespace PaymentMethod {
  /**
   * @export
   * @enum {string}
   */
  export enum GatewayEnum {
    Trustly = 'trustly',
    Billecta = 'billecta',
  }
  /**
   * @export
   * @enum {string}
   */
  export enum StatusEnum {
    Active = 'active',
    Inactive = 'inactive',
    Pending = 'pending',
    Cancelled = 'cancelled',
  }
}

/**
 *
 * @export
 * @interface PaymentPayload
 */
export interface PaymentPayload {
  /**
   *
   * @type {string}
   * @memberof PaymentPayload
   */
  account?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentPayload
   */
  bank?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentPayload
   */
  clearing?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentPayload
   */
  companyName?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentPayload
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof PaymentPayload
   */
  fullName?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentPayload
   */
  locality?: string;
  /**
   *
   * @type {any}
   * @memberof PaymentPayload
   */
  manual?: any;
  /**
   *
   * @type {string}
   * @memberof PaymentPayload
   */
  personnummer: string;
  /**
   *
   * @type {string}
   * @memberof PaymentPayload
   */
  phone: string;
  /**
   *
   * @type {string}
   * @memberof PaymentPayload
   */
  postalCode?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentPayload
   */
  streetAddress?: string;
}

/**
 *
 * @export
 * @interface PaymentTransaction
 */
export interface PaymentTransaction {
  /**
   *
   * @type {number}
   * @memberof PaymentTransaction
   */
  amount: number;
  /**
   *
   * @type {Date}
   * @memberof PaymentTransaction
   */
  created?: Date;
  /**
   *
   * @type {string}
   * @memberof PaymentTransaction
   */
  gatewayId?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentTransaction
   */
  gatewayResponse?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentTransaction
   */
  id?: string;
  /**
   *
   * @type {Date}
   * @memberof PaymentTransaction
   */
  paid: Date;
  /**
   *
   * @type {Date}
   * @memberof PaymentTransaction
   */
  paidFrom: Date;
  /**
   *
   * @type {Date}
   * @memberof PaymentTransaction
   */
  paidUntil: Date;
  /**
   *
   * @type {PaymentMethod}
   * @memberof PaymentTransaction
   */
  paymentMethod?: PaymentMethod;
  /**
   *
   * @type {number}
   * @memberof PaymentTransaction
   */
  price: number;
  /**
   *
   * @type {string}
   * @memberof PaymentTransaction
   */
  status?: PaymentTransaction.StatusEnum;
  /**
   *
   * @type {Date}
   * @memberof PaymentTransaction
   */
  updated?: Date;
}

/**
 * @export
 * @namespace PaymentTransaction
 */
export namespace PaymentTransaction {
  /**
   * @export
   * @enum {string}
   */
  export enum StatusEnum {
    Pending = 'pending',
    Success = 'success',
    Failure = 'failure',
  }
}

/**
 *
 * @export
 * @interface Person
 */
export interface Person {
  /**
   *
   * @type {string}
   * @memberof Person
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof Person
   */
  personnummer?: string;
  /**
   *
   * @type {string}
   * @memberof Person
   */
  phone?: string;
  /**
   *
   * @type {Quiz}
   * @memberof Person
   */
  quiz?: Quiz;
  /**
   *
   * @type {string}
   * @memberof Person
   */
  type: Person.TypeEnum;
}

/**
 * @export
 * @namespace Person
 */
export namespace Person {
  /**
   * @export
   * @enum {string}
   */
  export enum TypeEnum {
    Main = 'main',
    Spouse = 'spouse',
    Child = 'child',
  }
}

/**
 *
 * @export
 * @interface Pricing
 */
export interface Pricing {
  /**
   *
   * @type {number}
   * @memberof Pricing
   */
  commissionOverride?: number;
  /**
   *
   * @type {Date}
   * @memberof Pricing
   */
  created?: Date;
  /**
   *
   * @type {ProductPricing}
   * @memberof Pricing
   */
  health: ProductPricing;
  /**
   *
   * @type {ProductPricing}
   * @memberof Pricing
   */
  health_checkup: ProductPricing;
  /**
   *
   * @type {ProductPricing}
   * @memberof Pricing
   */
  health_plus: ProductPricing;
  /**
   *
   * @type {string}
   * @memberof Pricing
   */
  id: string;
  /**
   *
   * @type {Date}
   * @memberof Pricing
   */
  updated?: Date;
  /**
   *
   * @type {string}
   * @memberof Pricing
   */
  utm?: string;
}

/**
 *
 * @export
 * @interface PricingPayload
 */
export interface PricingPayload {
  /**
   *
   * @type {number}
   * @memberof PricingPayload
   */
  commissionOverride?: number;
  /**
   *
   * @type {ProductPricingPayload}
   * @memberof PricingPayload
   */
  health: ProductPricingPayload;
  /**
   *
   * @type {ProductPricingPayload}
   * @memberof PricingPayload
   */
  health_checkup: ProductPricingPayload;
  /**
   *
   * @type {ProductPricingPayload}
   * @memberof PricingPayload
   */
  health_plus: ProductPricingPayload;
  /**
   *
   * @type {string}
   * @memberof PricingPayload
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof PricingPayload
   */
  utm?: string;
}

/**
 *
 * @export
 * @interface Product
 */
export interface Product {
  /**
   *
   * @type {Account}
   * @memberof Product
   */
  account?: Account;
  /**
   *
   * @type {ProductAnalysis}
   * @memberof Product
   */
  analysis?: ProductAnalysis;
  /**
   *
   * @type {Array<string>}
   * @memberof Product
   */
  availableTreatments?: Array<Product.AvailableTreatmentsEnum>;
  /**
   *
   * @type {Date}
   * @memberof Product
   */
  created?: Date;
  /**
   *
   * @type {boolean}
   * @memberof Product
   */
  hideFromUI?: boolean;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  id: string;
  /**
   *
   * @type {ProductMetaData}
   * @memberof Product
   */
  meta?: ProductMetaData;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  nextRenewal?: string;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  note?: string;
  /**
   *
   * @type {Order}
   * @memberof Product
   */
  order?: Order;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  paidFrom?: string;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  paidUntil?: string;
  /**
   *
   * @type {Quiz}
   * @memberof Product
   */
  quiz: Quiz;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  status: Product.StatusEnum;
  /**
   *
   * @type {Array<PaymentTransaction>}
   * @memberof Product
   */
  transactions: Array<PaymentTransaction>;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  type: Product.TypeEnum;
  /**
   *
   * @type {Array<string>}
   * @memberof Product
   */
  unavailableTreatments?: Array<Product.UnavailableTreatmentsEnum>;
  /**
   *
   * @type {Date}
   * @memberof Product
   */
  updated?: Date;
}

/**
 * @export
 * @namespace Product
 */
export namespace Product {
  /**
   * @export
   * @enum {string}
   */
  export enum AvailableTreatmentsEnum {
    Cancer = 'cancer',
    Coronary = 'coronary',
    HeartValve = 'heart_valve',
    Neurosurgery = 'neurosurgery',
    Intracranial = 'intracranial',
    Organ = 'organ',
    Bone = 'bone',
    HeartVessels = 'heart_vessels',
    ThyroidGland = 'thyroid_gland',
    Diabetes = 'diabetes',
    VitaminsMinerals = 'vitamins_minerals',
    Liver = 'liver',
    Renal = 'renal',
    Blood = 'blood',
    Inflammation = 'inflammation',
  }
  /**
   * @export
   * @enum {string}
   */
  export enum StatusEnum {
    Pending = 'pending',
    Active = 'active',
    Inactive = 'inactive',
    Cancelled = 'cancelled',
    Completed = 'completed',
  }
  /**
   * @export
   * @enum {string}
   */
  export enum TypeEnum {
    HealthPlus = 'health_plus',
    Health = 'health',
    HealthCheckup = 'health_checkup',
  }
  /**
   * @export
   * @enum {string}
   */
  export enum UnavailableTreatmentsEnum {
    Cancer = 'cancer',
    Coronary = 'coronary',
    HeartValve = 'heart_valve',
    Neurosurgery = 'neurosurgery',
    Intracranial = 'intracranial',
    Organ = 'organ',
    Bone = 'bone',
    HeartVessels = 'heart_vessels',
    ThyroidGland = 'thyroid_gland',
    Diabetes = 'diabetes',
    VitaminsMinerals = 'vitamins_minerals',
    Liver = 'liver',
    Renal = 'renal',
    Blood = 'blood',
    Inflammation = 'inflammation',
  }
}

/**
 *
 * @export
 * @interface ProductAnalysis
 */
export interface ProductAnalysis {
  /**
   *
   * @type {string}
   * @memberof ProductAnalysis
   */
  BHA1CI?: ProductAnalysis.BHA1CIEnum;
  /**
   *
   * @type {string}
   * @memberof ProductAnalysis
   */
  PALAT?: ProductAnalysis.PALATEnum;
  /**
   *
   * @type {string}
   * @memberof ProductAnalysis
   */
  PASAT?: ProductAnalysis.PASATEnum;
  /**
   *
   * @type {string}
   * @memberof ProductAnalysis
   */
  PBA1KV?: ProductAnalysis.PBA1KVEnum;
  /**
   *
   * @type {string}
   * @memberof ProductAnalysis
   */
  PFE?: ProductAnalysis.PFEEnum;
  /**
   *
   * @type {string}
   * @memberof ProductAnalysis
   */
  PFERC?: ProductAnalysis.PFERCEnum;
  /**
   *
   * @type {string}
   * @memberof ProductAnalysis
   */
  PGLU?: ProductAnalysis.PGLUEnum;
  /**
   *
   * @type {string}
   * @memberof ProductAnalysis
   */
  PHDL?: ProductAnalysis.PHDLEnum;
  /**
   *
   * @type {string}
   * @memberof ProductAnalysis
   */
  PKA?: ProductAnalysis.PKAEnum;
  /**
   *
   * @type {string}
   * @memberof ProductAnalysis
   */
  PKOL?: ProductAnalysis.PKOLEnum;
  /**
   *
   * @type {string}
   * @memberof ProductAnalysis
   */
  PKREAT?: ProductAnalysis.PKREATEnum;
  /**
   *
   * @type {string}
   * @memberof ProductAnalysis
   */
  PLDL?: ProductAnalysis.PLDLEnum;
  /**
   *
   * @type {string}
   * @memberof ProductAnalysis
   */
  PMG?: ProductAnalysis.PMGEnum;
  /**
   *
   * @type {string}
   * @memberof ProductAnalysis
   */
  PNA?: ProductAnalysis.PNAEnum;
  /**
   *
   * @type {string}
   * @memberof ProductAnalysis
   */
  PPSA?: ProductAnalysis.PPSAEnum;
  /**
   *
   * @type {string}
   * @memberof ProductAnalysis
   */
  PRMHEM?: ProductAnalysis.PRMHEMEnum;
  /**
   *
   * @type {string}
   * @memberof ProductAnalysis
   */
  PT3FC?: ProductAnalysis.PT3FCEnum;
  /**
   *
   * @type {string}
   * @memberof ProductAnalysis
   */
  PT4FC?: ProductAnalysis.PT4FCEnum;
  /**
   *
   * @type {string}
   * @memberof ProductAnalysis
   */
  PTG?: ProductAnalysis.PTGEnum;
  /**
   *
   * @type {string}
   * @memberof ProductAnalysis
   */
  PTSH?: ProductAnalysis.PTSHEnum;
  /**
   *
   * @type {string}
   * @memberof ProductAnalysis
   */
  SB12?: ProductAnalysis.SB12Enum;
  /**
   *
   * @type {string}
   * @memberof ProductAnalysis
   */
  SFOL?: ProductAnalysis.SFOLEnum;
  /**
   *
   * @type {string}
   * @memberof ProductAnalysis
   */
  STESTC?: ProductAnalysis.STESTCEnum;
  /**
   *
   * @type {string}
   * @memberof ProductAnalysis
   */
  SVITD25?: ProductAnalysis.SVITD25Enum;
}

/**
 * @export
 * @namespace ProductAnalysis
 */
export namespace ProductAnalysis {
  /**
   * @export
   * @enum {string}
   */
  export enum BHA1CIEnum {
    HeartVessels = 'heart_vessels',
    ThyroidGland = 'thyroid_gland',
    Diabetes = 'diabetes',
    VitaminsMinerals = 'vitamins_minerals',
    Liver = 'liver',
    Renal = 'renal',
    Blood = 'blood',
    Inflammation = 'inflammation',
  }
  /**
   * @export
   * @enum {string}
   */
  export enum PALATEnum {
    HeartVessels = 'heart_vessels',
    ThyroidGland = 'thyroid_gland',
    Diabetes = 'diabetes',
    VitaminsMinerals = 'vitamins_minerals',
    Liver = 'liver',
    Renal = 'renal',
    Blood = 'blood',
    Inflammation = 'inflammation',
  }
  /**
   * @export
   * @enum {string}
   */
  export enum PASATEnum {
    HeartVessels = 'heart_vessels',
    ThyroidGland = 'thyroid_gland',
    Diabetes = 'diabetes',
    VitaminsMinerals = 'vitamins_minerals',
    Liver = 'liver',
    Renal = 'renal',
    Blood = 'blood',
    Inflammation = 'inflammation',
  }
  /**
   * @export
   * @enum {string}
   */
  export enum PBA1KVEnum {
    HeartVessels = 'heart_vessels',
    ThyroidGland = 'thyroid_gland',
    Diabetes = 'diabetes',
    VitaminsMinerals = 'vitamins_minerals',
    Liver = 'liver',
    Renal = 'renal',
    Blood = 'blood',
    Inflammation = 'inflammation',
  }
  /**
   * @export
   * @enum {string}
   */
  export enum PFEEnum {
    HeartVessels = 'heart_vessels',
    ThyroidGland = 'thyroid_gland',
    Diabetes = 'diabetes',
    VitaminsMinerals = 'vitamins_minerals',
    Liver = 'liver',
    Renal = 'renal',
    Blood = 'blood',
    Inflammation = 'inflammation',
  }
  /**
   * @export
   * @enum {string}
   */
  export enum PFERCEnum {
    HeartVessels = 'heart_vessels',
    ThyroidGland = 'thyroid_gland',
    Diabetes = 'diabetes',
    VitaminsMinerals = 'vitamins_minerals',
    Liver = 'liver',
    Renal = 'renal',
    Blood = 'blood',
    Inflammation = 'inflammation',
  }
  /**
   * @export
   * @enum {string}
   */
  export enum PGLUEnum {
    HeartVessels = 'heart_vessels',
    ThyroidGland = 'thyroid_gland',
    Diabetes = 'diabetes',
    VitaminsMinerals = 'vitamins_minerals',
    Liver = 'liver',
    Renal = 'renal',
    Blood = 'blood',
    Inflammation = 'inflammation',
  }
  /**
   * @export
   * @enum {string}
   */
  export enum PHDLEnum {
    HeartVessels = 'heart_vessels',
    ThyroidGland = 'thyroid_gland',
    Diabetes = 'diabetes',
    VitaminsMinerals = 'vitamins_minerals',
    Liver = 'liver',
    Renal = 'renal',
    Blood = 'blood',
    Inflammation = 'inflammation',
  }
  /**
   * @export
   * @enum {string}
   */
  export enum PKAEnum {
    HeartVessels = 'heart_vessels',
    ThyroidGland = 'thyroid_gland',
    Diabetes = 'diabetes',
    VitaminsMinerals = 'vitamins_minerals',
    Liver = 'liver',
    Renal = 'renal',
    Blood = 'blood',
    Inflammation = 'inflammation',
  }
  /**
   * @export
   * @enum {string}
   */
  export enum PKOLEnum {
    HeartVessels = 'heart_vessels',
    ThyroidGland = 'thyroid_gland',
    Diabetes = 'diabetes',
    VitaminsMinerals = 'vitamins_minerals',
    Liver = 'liver',
    Renal = 'renal',
    Blood = 'blood',
    Inflammation = 'inflammation',
  }
  /**
   * @export
   * @enum {string}
   */
  export enum PKREATEnum {
    HeartVessels = 'heart_vessels',
    ThyroidGland = 'thyroid_gland',
    Diabetes = 'diabetes',
    VitaminsMinerals = 'vitamins_minerals',
    Liver = 'liver',
    Renal = 'renal',
    Blood = 'blood',
    Inflammation = 'inflammation',
  }
  /**
   * @export
   * @enum {string}
   */
  export enum PLDLEnum {
    HeartVessels = 'heart_vessels',
    ThyroidGland = 'thyroid_gland',
    Diabetes = 'diabetes',
    VitaminsMinerals = 'vitamins_minerals',
    Liver = 'liver',
    Renal = 'renal',
    Blood = 'blood',
    Inflammation = 'inflammation',
  }
  /**
   * @export
   * @enum {string}
   */
  export enum PMGEnum {
    HeartVessels = 'heart_vessels',
    ThyroidGland = 'thyroid_gland',
    Diabetes = 'diabetes',
    VitaminsMinerals = 'vitamins_minerals',
    Liver = 'liver',
    Renal = 'renal',
    Blood = 'blood',
    Inflammation = 'inflammation',
  }
  /**
   * @export
   * @enum {string}
   */
  export enum PNAEnum {
    HeartVessels = 'heart_vessels',
    ThyroidGland = 'thyroid_gland',
    Diabetes = 'diabetes',
    VitaminsMinerals = 'vitamins_minerals',
    Liver = 'liver',
    Renal = 'renal',
    Blood = 'blood',
    Inflammation = 'inflammation',
  }
  /**
   * @export
   * @enum {string}
   */
  export enum PPSAEnum {
    HeartVessels = 'heart_vessels',
    ThyroidGland = 'thyroid_gland',
    Diabetes = 'diabetes',
    VitaminsMinerals = 'vitamins_minerals',
    Liver = 'liver',
    Renal = 'renal',
    Blood = 'blood',
    Inflammation = 'inflammation',
  }
  /**
   * @export
   * @enum {string}
   */
  export enum PRMHEMEnum {
    HeartVessels = 'heart_vessels',
    ThyroidGland = 'thyroid_gland',
    Diabetes = 'diabetes',
    VitaminsMinerals = 'vitamins_minerals',
    Liver = 'liver',
    Renal = 'renal',
    Blood = 'blood',
    Inflammation = 'inflammation',
  }
  /**
   * @export
   * @enum {string}
   */
  export enum PT3FCEnum {
    HeartVessels = 'heart_vessels',
    ThyroidGland = 'thyroid_gland',
    Diabetes = 'diabetes',
    VitaminsMinerals = 'vitamins_minerals',
    Liver = 'liver',
    Renal = 'renal',
    Blood = 'blood',
    Inflammation = 'inflammation',
  }
  /**
   * @export
   * @enum {string}
   */
  export enum PT4FCEnum {
    HeartVessels = 'heart_vessels',
    ThyroidGland = 'thyroid_gland',
    Diabetes = 'diabetes',
    VitaminsMinerals = 'vitamins_minerals',
    Liver = 'liver',
    Renal = 'renal',
    Blood = 'blood',
    Inflammation = 'inflammation',
  }
  /**
   * @export
   * @enum {string}
   */
  export enum PTGEnum {
    HeartVessels = 'heart_vessels',
    ThyroidGland = 'thyroid_gland',
    Diabetes = 'diabetes',
    VitaminsMinerals = 'vitamins_minerals',
    Liver = 'liver',
    Renal = 'renal',
    Blood = 'blood',
    Inflammation = 'inflammation',
  }
  /**
   * @export
   * @enum {string}
   */
  export enum PTSHEnum {
    HeartVessels = 'heart_vessels',
    ThyroidGland = 'thyroid_gland',
    Diabetes = 'diabetes',
    VitaminsMinerals = 'vitamins_minerals',
    Liver = 'liver',
    Renal = 'renal',
    Blood = 'blood',
    Inflammation = 'inflammation',
  }
  /**
   * @export
   * @enum {string}
   */
  export enum SB12Enum {
    HeartVessels = 'heart_vessels',
    ThyroidGland = 'thyroid_gland',
    Diabetes = 'diabetes',
    VitaminsMinerals = 'vitamins_minerals',
    Liver = 'liver',
    Renal = 'renal',
    Blood = 'blood',
    Inflammation = 'inflammation',
  }
  /**
   * @export
   * @enum {string}
   */
  export enum SFOLEnum {
    HeartVessels = 'heart_vessels',
    ThyroidGland = 'thyroid_gland',
    Diabetes = 'diabetes',
    VitaminsMinerals = 'vitamins_minerals',
    Liver = 'liver',
    Renal = 'renal',
    Blood = 'blood',
    Inflammation = 'inflammation',
  }
  /**
   * @export
   * @enum {string}
   */
  export enum STESTCEnum {
    HeartVessels = 'heart_vessels',
    ThyroidGland = 'thyroid_gland',
    Diabetes = 'diabetes',
    VitaminsMinerals = 'vitamins_minerals',
    Liver = 'liver',
    Renal = 'renal',
    Blood = 'blood',
    Inflammation = 'inflammation',
  }
  /**
   * @export
   * @enum {string}
   */
  export enum SVITD25Enum {
    HeartVessels = 'heart_vessels',
    ThyroidGland = 'thyroid_gland',
    Diabetes = 'diabetes',
    VitaminsMinerals = 'vitamins_minerals',
    Liver = 'liver',
    Renal = 'renal',
    Blood = 'blood',
    Inflammation = 'inflammation',
  }
}

/**
 *
 * @export
 * @interface ProductMetaData
 */
export interface ProductMetaData {
  /**
   *
   * @type {string}
   * @memberof ProductMetaData
   */
  city?: string;
}

/**
 *
 * @export
 * @interface ProductPayload
 */
export interface ProductPayload {
  /**
   *
   * @type {string}
   * @memberof ProductPayload
   */
  owner: string;
  /**
   *
   * @type {string}
   * @memberof ProductPayload
   */
  personnummer?: string;
  /**
   *
   * @type {string}
   * @memberof ProductPayload
   */
  productType: string;
  /**
   *
   * @type {string}
   * @memberof ProductPayload
   */
  quiz: string;
}

/**
 *
 * @export
 * @interface ProductPricing
 */
export interface ProductPricing {
  /**
   *
   * @type {{ [key: string]: number; }}
   * @memberof ProductPricing
   */
  couple: { [key: string]: number };
  /**
   *
   * @type {{ [key: string]: number; }}
   * @memberof ProductPricing
   */
  family: { [key: string]: number };
  /**
   *
   * @type {{ [key: string]: number; }}
   * @memberof ProductPricing
   */
  single: { [key: string]: number };
  /**
   *
   * @type {{ [key: string]: number; }}
   * @memberof ProductPricing
   */
  single_child: { [key: string]: number };
}

/**
 *
 * @export
 * @interface ProductPricingPayload
 */
export interface ProductPricingPayload {
  /**
   *
   * @type {{ [key: string]: number; }}
   * @memberof ProductPricingPayload
   */
  couple: { [key: string]: number };
  /**
   *
   * @type {{ [key: string]: number; }}
   * @memberof ProductPricingPayload
   */
  family: { [key: string]: number };
  /**
   *
   * @type {string}
   * @memberof ProductPricingPayload
   */
  id?: string;
  /**
   *
   * @type {{ [key: string]: number; }}
   * @memberof ProductPricingPayload
   */
  single: { [key: string]: number };
  /**
   *
   * @type {{ [key: string]: number; }}
   * @memberof ProductPricingPayload
   */
  single_child: { [key: string]: number };
}

/**
 *
 * @export
 * @interface Quiz
 */
export interface Quiz {
  /**
   *
   * @type {number}
   * @memberof Quiz
   */
  age?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof Quiz
   */
  availableTreatments?: Array<Quiz.AvailableTreatmentsEnum>;
  /**
   *
   * @type {number}
   * @memberof Quiz
   */
  brain?: number;
  /**
   *
   * @type {number}
   * @memberof Quiz
   */
  cancer?: number;
  /**
   *
   * @type {number}
   * @memberof Quiz
   */
  chronic?: number;
  /**
   *
   * @type {Date}
   * @memberof Quiz
   */
  created?: Date;
  /**
   *
   * @type {number}
   * @memberof Quiz
   */
  diabetes?: number;
  /**
   *
   * @type {number}
   * @memberof Quiz
   */
  examinations?: number;
  /**
   *
   * @type {number}
   * @memberof Quiz
   */
  heart?: number;
  /**
   *
   * @type {string}
   * @memberof Quiz
   */
  id: string;
  /**
   *
   * @type {number}
   * @memberof Quiz
   */
  leukemia?: number;
  /**
   *
   * @type {number}
   * @memberof Quiz
   */
  needInsurance?: number;
  /**
   *
   * @type {number}
   * @memberof Quiz
   */
  needInternational?: number;
  /**
   *
   * @type {number}
   * @memberof Quiz
   */
  needLeadingDoctors?: number;
  /**
   *
   * @type {number}
   * @memberof Quiz
   */
  needResult?: number;
  /**
   *
   * @type {number}
   * @memberof Quiz
   */
  needSecondOpinion?: number;
  /**
   *
   * @type {number}
   * @memberof Quiz
   */
  potentialCancer?: number;
  /**
   *
   * @type {Pricing}
   * @memberof Quiz
   */
  pricing?: Pricing;
  /**
   *
   * @type {Array<string>}
   * @memberof Quiz
   */
  productTypes: Array<Quiz.ProductTypesEnum>;
  /**
   *
   * @type {number}
   * @memberof Quiz
   */
  tumor?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof Quiz
   */
  unavailableTreatments?: Array<Quiz.UnavailableTreatmentsEnum>;
  /**
   *
   * @type {Date}
   * @memberof Quiz
   */
  updated?: Date;
}

/**
 * @export
 * @namespace Quiz
 */
export namespace Quiz {
  /**
   * @export
   * @enum {string}
   */
  export enum AvailableTreatmentsEnum {
    Cancer = 'cancer',
    Coronary = 'coronary',
    HeartValve = 'heart_valve',
    Neurosurgery = 'neurosurgery',
    Intracranial = 'intracranial',
    Organ = 'organ',
    Bone = 'bone',
    HeartVessels = 'heart_vessels',
    ThyroidGland = 'thyroid_gland',
    Diabetes = 'diabetes',
    VitaminsMinerals = 'vitamins_minerals',
    Liver = 'liver',
    Renal = 'renal',
    Blood = 'blood',
    Inflammation = 'inflammation',
  }
  /**
   * @export
   * @enum {string}
   */
  export enum ProductTypesEnum {
    HealthPlus = 'health_plus',
    Health = 'health',
    HealthCheckup = 'health_checkup',
  }
  /**
   * @export
   * @enum {string}
   */
  export enum UnavailableTreatmentsEnum {
    Cancer = 'cancer',
    Coronary = 'coronary',
    HeartValve = 'heart_valve',
    Neurosurgery = 'neurosurgery',
    Intracranial = 'intracranial',
    Organ = 'organ',
    Bone = 'bone',
    HeartVessels = 'heart_vessels',
    ThyroidGland = 'thyroid_gland',
    Diabetes = 'diabetes',
    VitaminsMinerals = 'vitamins_minerals',
    Liver = 'liver',
    Renal = 'renal',
    Blood = 'blood',
    Inflammation = 'inflammation',
  }
}

/**
 *
 * @export
 * @interface QuizInvite
 */
export interface QuizInvite {
  /**
   *
   * @type {string}
   * @memberof QuizInvite
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof QuizInvite
   */
  personnummer: string;
}

/**
 *
 * @export
 * @interface Sensitive
 */
export interface Sensitive {
  /**
   *
   * @type {Account}
   * @memberof Sensitive
   */
  account?: Account;
  /**
   *
   * @type {Date}
   * @memberof Sensitive
   */
  created?: Date;
  /**
   *
   * @type {string}
   * @memberof Sensitive
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof Sensitive
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof Sensitive
   */
  fullName: string;
  /**
   *
   * @type {string}
   * @memberof Sensitive
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof Sensitive
   */
  lastName: string;
  /**
   *
   * @type {string}
   * @memberof Sensitive
   */
  locality: string;
  /**
   *
   * @type {string}
   * @memberof Sensitive
   */
  personnummer: string;
  /**
   *
   * @type {string}
   * @memberof Sensitive
   */
  phone: string;
  /**
   *
   * @type {string}
   * @memberof Sensitive
   */
  postalCode: string;
  /**
   *
   * @type {string}
   * @memberof Sensitive
   */
  streetAddress: string;
  /**
   *
   * @type {Date}
   * @memberof Sensitive
   */
  updated?: Date;
}

/**
 *
 * @export
 * @interface SensitiveLookupPayload
 */
export interface SensitiveLookupPayload {
  /**
   *
   * @type {string}
   * @memberof SensitiveLookupPayload
   */
  nummer: string;
  /**
   *
   * @type {string}
   * @memberof SensitiveLookupPayload
   */
  type?: SensitiveLookupPayload.TypeEnum;
}

/**
 * @export
 * @namespace SensitiveLookupPayload
 */
export namespace SensitiveLookupPayload {
  /**
   * @export
   * @enum {string}
   */
  export enum TypeEnum {
    Person = 'person',
    Company = 'company',
  }
}

/**
 *
 * @export
 * @interface SensitiveUpdatePayload
 */
export interface SensitiveUpdatePayload {
  /**
   *
   * @type {string}
   * @memberof SensitiveUpdatePayload
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof SensitiveUpdatePayload
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof SensitiveUpdatePayload
   */
  phone?: string;
}

/**
 * DefaultApi - fetch parameter creator
 * @export
 */
export const DefaultApiFetchParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Download GDPR-compliant personal data archive
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountControllerExport(token: string, options: any = {}): FetchArgs {
      // verify required parameter 'token' is not null or undefined
      if (token === null || token === undefined) {
        throw new RequiredError(
          'token',
          'Required parameter token was null or undefined when calling accountControllerExport.',
        );
      }
      const localVarPath = `/account/gdpr`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('Authorization')
            : configuration.apiKey;
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
      }

      if (token !== undefined) {
        localVarQueryParameter['token'] = token;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Retrieve current account and associated data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountControllerGet(options: any = {}): FetchArgs {
      const localVarPath = `/account`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('Authorization')
            : configuration.apiKey;
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Start account deletion
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountControllerStartDeleting(options: any = {}): FetchArgs {
      const localVarPath = `/account/gdpr`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign(
        { method: 'DELETE' },
        options,
      );
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('Authorization')
            : configuration.apiKey;
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Stop account deletion
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountControllerStopDeleting(options: any = {}): FetchArgs {
      const localVarPath = `/account/gdpr`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('Authorization')
            : configuration.apiKey;
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Switch to associated account
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountControllerSwitch(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling accountControllerSwitch.',
        );
      }
      const localVarPath = `/account/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('Authorization')
            : configuration.apiKey;
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Assign broker to account
     * @param {BrokerToAccountPayload} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerAssignBrokerToAccount(
      body: BrokerToAccountPayload,
      options: any = {},
    ): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling adminControllerAssignBrokerToAccount.',
        );
      }
      const localVarPath = `/admin/brokers/accounts`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('Authorization')
            : configuration.apiKey;
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );
      const needsSerialization =
        'BrokerToAccountPayload' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization
        ? JSON.stringify(body || {})
        : body || '';

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Change order payer
     * @param {ChangePayerPayload} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerChangePayer(
      body: ChangePayerPayload,
      options: any = {},
    ): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling adminControllerChangePayer.',
        );
      }
      const localVarPath = `/admin/order`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('Authorization')
            : configuration.apiKey;
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );
      const needsSerialization =
        'ChangePayerPayload' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization
        ? JSON.stringify(body || {})
        : body || '';

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete broker
     * @param {Broker} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerDeleteBroker(body: Broker, options: any = {}): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling adminControllerDeleteBroker.',
        );
      }
      const localVarPath = `/admin/brokers`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign(
        { method: 'DELETE' },
        options,
      );
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('Authorization')
            : configuration.apiKey;
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );
      const needsSerialization =
        'Broker' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization
        ? JSON.stringify(body || {})
        : body || '';

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete offer
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerDeleteOffer(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling adminControllerDeleteOffer.',
        );
      }
      const localVarPath = `/admin/offer/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign(
        { method: 'DELETE' },
        options,
      );
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('Authorization')
            : configuration.apiKey;
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Generate accounts report
     * @param {AccountsReportPayload} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerGenerateReport(
      body: AccountsReportPayload,
      options: any = {},
    ): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling adminControllerGenerateReport.',
        );
      }
      const localVarPath = `/admin/accounts/report`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('Authorization')
            : configuration.apiKey;
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );
      const needsSerialization =
        'AccountsReportPayload' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization
        ? JSON.stringify(body || {})
        : body || '';

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Export accounts as MIS Life XML
     * @param {MislifePayload} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerGenerateReportMISLife(
      body: MislifePayload,
      options: any = {},
    ): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling adminControllerGenerateReportMISLife.',
        );
      }
      const localVarPath = `/admin/accounts/report/mislife`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('Authorization')
            : configuration.apiKey;
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );
      const needsSerialization =
        'MislifePayload' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization
        ? JSON.stringify(body || {})
        : body || '';

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Export broker company accounts as MIS Life XML archive
     * @param {string} brokerCompanyId
     * @param {Array<string>} productTypes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerGenerateReportMISLifeArchive(
      brokerCompanyId: string,
      productTypes: Array<string>,
      options: any = {},
    ): FetchArgs {
      // verify required parameter 'brokerCompanyId' is not null or undefined
      if (brokerCompanyId === null || brokerCompanyId === undefined) {
        throw new RequiredError(
          'brokerCompanyId',
          'Required parameter brokerCompanyId was null or undefined when calling adminControllerGenerateReportMISLifeArchive.',
        );
      }
      // verify required parameter 'productTypes' is not null or undefined
      if (productTypes === null || productTypes === undefined) {
        throw new RequiredError(
          'productTypes',
          'Required parameter productTypes was null or undefined when calling adminControllerGenerateReportMISLifeArchive.',
        );
      }
      const localVarPath =
        `/admin/accounts/report/mislife/{brokerCompanyId}`.replace(
          `{${'brokerCompanyId'}}`,
          encodeURIComponent(String(brokerCompanyId)),
        );
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('Authorization')
            : configuration.apiKey;
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
      }

      if (productTypes) {
        localVarQueryParameter['productTypes'] = productTypes;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Export accounts report as XLS
     * @param {AccountsReportPayload} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerGenerateReportXLS(
      body: AccountsReportPayload,
      options: any = {},
    ): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling adminControllerGenerateReportXLS.',
        );
      }
      const localVarPath = `/admin/accounts/report/xls`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('Authorization')
            : configuration.apiKey;
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );
      const needsSerialization =
        'AccountsReportPayload' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization
        ? JSON.stringify(body || {})
        : body || '';

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary List all accounts data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerGetAccounts(options: any = {}): FetchArgs {
      const localVarPath = `/admin/accounts`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('Authorization')
            : configuration.apiKey;
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary List all brokers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerGetBrokers(options: any = {}): FetchArgs {
      const localVarPath = `/admin/brokers`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('Authorization')
            : configuration.apiKey;
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get invite for brokers
     * @param {BrokerInvitePayload} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerGetBrokersInvite(
      body: BrokerInvitePayload,
      options: any = {},
    ): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling adminControllerGetBrokersInvite.',
        );
      }
      const localVarPath = `/admin/brokers/invite`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('Authorization')
            : configuration.apiKey;
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );
      const needsSerialization =
        'BrokerInvitePayload' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization
        ? JSON.stringify(body || {})
        : body || '';

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {MetricsPayload} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerGetMRR(body: MetricsPayload, options: any = {}): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling adminControllerGetMRR.',
        );
      }
      const localVarPath = `/admin/metrics/mrr`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('Authorization')
            : configuration.apiKey;
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );
      const needsSerialization =
        'MetricsPayload' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization
        ? JSON.stringify(body || {})
        : body || '';

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get offers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerGetOffers(options: any = {}): FetchArgs {
      const localVarPath = `/admin/offer`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('Authorization')
            : configuration.apiKey;
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get all pricings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerGetPricings(options: any = {}): FetchArgs {
      const localVarPath = `/admin/pricing`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('Authorization')
            : configuration.apiKey;
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary List brokers and customers
     * @param {'health' | 'health_checkup'} [productType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerListBrokerCustomers(
      productType?: 'health' | 'health_checkup',
      options: any = {},
    ): FetchArgs {
      const localVarPath = `/admin/accounts/customers`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('Authorization')
            : configuration.apiKey;
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
      }

      if (productType !== undefined) {
        localVarQueryParameter['productType'] = productType;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Save broker
     * @param {Broker} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerSaveBroker(body: Broker, options: any = {}): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling adminControllerSaveBroker.',
        );
      }
      const localVarPath = `/admin/brokers`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('Authorization')
            : configuration.apiKey;
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );
      const needsSerialization =
        'Broker' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization
        ? JSON.stringify(body || {})
        : body || '';

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Save offer
     * @param {Offer} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerSaveOffer(body: Offer, options: any = {}): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling adminControllerSaveOffer.',
        );
      }
      const localVarPath = `/admin/offer`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('Authorization')
            : configuration.apiKey;
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );
      const needsSerialization =
        'Offer' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization
        ? JSON.stringify(body || {})
        : body || '';

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Save pricing
     * @param {PricingPayload} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerSavePricing(
      body: PricingPayload,
      options: any = {},
    ): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling adminControllerSavePricing.',
        );
      }
      const localVarPath = `/admin/pricing`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('Authorization')
            : configuration.apiKey;
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );
      const needsSerialization =
        'PricingPayload' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization
        ? JSON.stringify(body || {})
        : body || '';

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Cancel BankID auth session
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authControllerCancel(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling authControllerCancel.',
        );
      }
      const localVarPath = `/auth/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign(
        { method: 'DELETE' },
        options,
      );
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Initiate BankID auth session
     * @param {AuthPayload} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authControllerStart(body: AuthPayload, options: any = {}): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling authControllerStart.',
        );
      }
      const localVarPath = `/auth`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );
      const needsSerialization =
        'AuthPayload' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization
        ? JSON.stringify(body || {})
        : body || '';

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Verify status of BankID auth session
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authControllerVerify(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling authControllerVerify.',
        );
      }
      const localVarPath = `/auth/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Check status and list of bank accounts
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bankControllerCheckFetching(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling bankControllerCheckFetching.',
        );
      }
      const localVarPath = `/bank/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Request auth to fetch bank accounts
     * @param {BankRequestPayload} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bankControllerStartFetching(
      body: BankRequestPayload,
      options: any = {},
    ): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling bankControllerStartFetching.',
        );
      }
      const localVarPath = `/bank`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );
      const needsSerialization =
        'BankRequestPayload' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization
        ? JSON.stringify(body || {})
        : body || '';

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Register new broker
     * @param {BrokerPayload} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    brokerControllerCreateBroker(
      body: BrokerPayload,
      options: any = {},
    ): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling brokerControllerCreateBroker.',
        );
      }
      const localVarPath = `/broker`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );
      const needsSerialization =
        'BrokerPayload' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization
        ? JSON.stringify(body || {})
        : body || '';

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Check if insurance agreement generated
     * @param {string} id
     * @param {string} product
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    documentControllerCheckAgreement(
      id: string,
      product: string,
      options: any = {},
    ): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling documentControllerCheckAgreement.',
        );
      }
      // verify required parameter 'product' is not null or undefined
      if (product === null || product === undefined) {
        throw new RequiredError(
          'product',
          'Required parameter product was null or undefined when calling documentControllerCheckAgreement.',
        );
      }
      const localVarPath = `/document/{id}/{product}`
        .replace(`{${'id'}}`, encodeURIComponent(String(id)))
        .replace(`{${'product'}}`, encodeURIComponent(String(product)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'HEAD' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get insurance agreement for relevant account and product
     * @param {string} id
     * @param {string} related
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    documentControllerGetAgreement(
      id: string,
      related: string,
      options: any = {},
    ): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling documentControllerGetAgreement.',
        );
      }
      // verify required parameter 'related' is not null or undefined
      if (related === null || related === undefined) {
        throw new RequiredError(
          'related',
          'Required parameter related was null or undefined when calling documentControllerGetAgreement.',
        );
      }
      const localVarPath = `/document/{id}/{related}.pdf`
        .replace(`{${'id'}}`, encodeURIComponent(String(id)))
        .replace(`{${'related'}}`, encodeURIComponent(String(related)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get monthly report
     * @param {string} id
     * @param {string} related
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    documentControllerGetMonthlyReport(
      id: string,
      related: string,
      options: any = {},
    ): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling documentControllerGetMonthlyReport.',
        );
      }
      // verify required parameter 'related' is not null or undefined
      if (related === null || related === undefined) {
        throw new RequiredError(
          'related',
          'Required parameter related was null or undefined when calling documentControllerGetMonthlyReport.',
        );
      }
      const localVarPath = `/document/broker/{related}/{id}.xls`
        .replace(`{${'id'}}`, encodeURIComponent(String(id)))
        .replace(`{${'related'}}`, encodeURIComponent(String(related)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get self-invoice PDF
     * @param {string} id
     * @param {string} related
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    documentControllerGetSelfInvoicePDF(
      id: string,
      related: string,
      options: any = {},
    ): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling documentControllerGetSelfInvoicePDF.',
        );
      }
      // verify required parameter 'related' is not null or undefined
      if (related === null || related === undefined) {
        throw new RequiredError(
          'related',
          'Required parameter related was null or undefined when calling documentControllerGetSelfInvoicePDF.',
        );
      }
      const localVarPath = `/document/broker/{related}/{id}.pdf`
        .replace(`{${'id'}}`, encodeURIComponent(String(id)))
        .replace(`{${'related'}}`, encodeURIComponent(String(related)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    eRemissControllerHandleNotification(options: any = {}): FetchArgs {
      const localVarPath = `/e-remiss`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    eRemissControllerHandlePostNotification(options: any = {}): FetchArgs {
      const localVarPath = `/e-remiss`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {ModelObject} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    giddirControllerHandlePostNotification(
      body: ModelObject,
      options: any = {},
    ): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling giddirControllerHandlePostNotification.',
        );
      }
      const localVarPath = `/giddir`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );
      const needsSerialization =
        'ModelObject' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization
        ? JSON.stringify(body || {})
        : body || '';

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Add new person to order
     * @param {string} id
     * @param {Person} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    orderControllerAddPerson(
      id: string,
      body: Person,
      options: any = {},
    ): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling orderControllerAddPerson.',
        );
      }
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling orderControllerAddPerson.',
        );
      }
      const localVarPath = `/order/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign(
        { method: 'PATCH' },
        options,
      );
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('Authorization')
            : configuration.apiKey;
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );
      const needsSerialization =
        'Person' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization
        ? JSON.stringify(body || {})
        : body || '';

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Add new product to order
     * @param {string} id
     * @param {ProductPayload} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    orderControllerAddProduct(
      id: string,
      body: ProductPayload,
      options: any = {},
    ): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling orderControllerAddProduct.',
        );
      }
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling orderControllerAddProduct.',
        );
      }
      const localVarPath = `/order/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('Authorization')
            : configuration.apiKey;
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );
      const needsSerialization =
        'ProductPayload' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization
        ? JSON.stringify(body || {})
        : body || '';

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Cancel product
     * @param {string} id
     * @param {string} note
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    orderControllerCancelProduct(
      id: string,
      note: string,
      options: any = {},
    ): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling orderControllerCancelProduct.',
        );
      }
      // verify required parameter 'note' is not null or undefined
      if (note === null || note === undefined) {
        throw new RequiredError(
          'note',
          'Required parameter note was null or undefined when calling orderControllerCancelProduct.',
        );
      }
      const localVarPath = `/order/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign(
        { method: 'DELETE' },
        options,
      );
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('Authorization')
            : configuration.apiKey;
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
      }

      if (note !== undefined) {
        localVarQueryParameter['note'] = note;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create new order for current account
     * @param {OrderOptionsPayload} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    orderControllerCreate(
      body: OrderOptionsPayload,
      options: any = {},
    ): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling orderControllerCreate.',
        );
      }
      const localVarPath = `/order`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('Authorization')
            : configuration.apiKey;
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );
      const needsSerialization =
        'OrderOptionsPayload' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization
        ? JSON.stringify(body || {})
        : body || '';

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get specific offer
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    orderControllerGetOffer(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling orderControllerGetOffer.',
        );
      }
      const localVarPath = `/order/offer/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get latest pricing
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    orderControllerGetPricing(options: any = {}): FetchArgs {
      const localVarPath = `/order/pricing`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get specific pricing
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    orderControllerGetPricingById(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling orderControllerGetPricingById.',
        );
      }
      const localVarPath = `/order/pricing/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create new order for multiple products
     * @param {OrderPayload} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    orderControllerMultiCreate(
      body: OrderPayload,
      options: any = {},
    ): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling orderControllerMultiCreate.',
        );
      }
      const localVarPath = `/order`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('Authorization')
            : configuration.apiKey;
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );
      const needsSerialization =
        'OrderPayload' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization
        ? JSON.stringify(body || {})
        : body || '';

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Preview order for multiple products
     * @param {OrderPayload} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    orderControllerMultiPreview(
      body: OrderPayload,
      options: any = {},
    ): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling orderControllerMultiPreview.',
        );
      }
      const localVarPath = `/order/preview`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );
      const needsSerialization =
        'OrderPayload' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization
        ? JSON.stringify(body || {})
        : body || '';

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Preview order for product
     * @param {string} productType
     * @param {number} paymentPeriod
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    orderControllerPreview(
      productType: string,
      paymentPeriod: number,
      options: any = {},
    ): FetchArgs {
      // verify required parameter 'productType' is not null or undefined
      if (productType === null || productType === undefined) {
        throw new RequiredError(
          'productType',
          'Required parameter productType was null or undefined when calling orderControllerPreview.',
        );
      }
      // verify required parameter 'paymentPeriod' is not null or undefined
      if (paymentPeriod === null || paymentPeriod === undefined) {
        throw new RequiredError(
          'paymentPeriod',
          'Required parameter paymentPeriod was null or undefined when calling orderControllerPreview.',
        );
      }
      const localVarPath = `/order/preview`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('Authorization')
            : configuration.apiKey;
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
      }

      if (productType !== undefined) {
        localVarQueryParameter['productType'] = productType;
      }

      if (paymentPeriod !== undefined) {
        localVarQueryParameter['paymentPeriod'] = paymentPeriod;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update order options
     * @param {string} id
     * @param {OrderOptionsPayload} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    orderControllerUpdateOrder(
      id: string,
      body: OrderOptionsPayload,
      options: any = {},
    ): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling orderControllerUpdateOrder.',
        );
      }
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling orderControllerUpdateOrder.',
        );
      }
      const localVarPath = `/order/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('Authorization')
            : configuration.apiKey;
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );
      const needsSerialization =
        'OrderOptionsPayload' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization
        ? JSON.stringify(body || {})
        : body || '';

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Connect new payment method
     * @param {PaymentPayload} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentControllerConnectPaymentMethod(
      body: PaymentPayload,
      options: any = {},
    ): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling paymentControllerConnectPaymentMethod.',
        );
      }
      const localVarPath = `/payment`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('Authorization')
            : configuration.apiKey;
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );
      const needsSerialization =
        'PaymentPayload' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization
        ? JSON.stringify(body || {})
        : body || '';

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Toggle payment method
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentControllerTogglePaymentMethod(
      id: string,
      options: any = {},
    ): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling paymentControllerTogglePaymentMethod.',
        );
      }
      const localVarPath = `/payment/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('Authorization')
            : configuration.apiKey;
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    productControllerGetLabs(options: any = {}): FetchArgs {
      const localVarPath = `/product/labs`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get quiz created within 30 days
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    quizControllerGet(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling quizControllerGet.',
        );
      }
      const localVarPath = `/quiz/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Invite customer to complete the health quiz
     * @param {string} id
     * @param {QuizInvite} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    quizControllerInvite(
      id: string,
      body: QuizInvite,
      options: any = {},
    ): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling quizControllerInvite.',
        );
      }
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling quizControllerInvite.',
        );
      }
      const localVarPath = `/quiz/{id}/invite`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );
      const needsSerialization =
        'QuizInvite' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization
        ? JSON.stringify(body || {})
        : body || '';

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Save quiz answers and get available product types
     * @param {boolean} complete
     * @param {Quiz} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    quizControllerSave(
      complete: boolean,
      body: Quiz,
      options: any = {},
    ): FetchArgs {
      // verify required parameter 'complete' is not null or undefined
      if (complete === null || complete === undefined) {
        throw new RequiredError(
          'complete',
          'Required parameter complete was null or undefined when calling quizControllerSave.',
        );
      }
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling quizControllerSave.',
        );
      }
      const localVarPath = `/quiz`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (complete !== undefined) {
        localVarQueryParameter['complete'] = complete;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );
      const needsSerialization =
        'Quiz' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization
        ? JSON.stringify(body || {})
        : body || '';

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Look up sensitive details
     * @param {SensitiveLookupPayload} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sensitiveControllerLookup(
      body: SensitiveLookupPayload,
      options: any = {},
    ): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling sensitiveControllerLookup.',
        );
      }
      const localVarPath = `/sensitive`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );
      const needsSerialization =
        'SensitiveLookupPayload' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization
        ? JSON.stringify(body || {})
        : body || '';

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update sensitive details
     * @param {SensitiveUpdatePayload} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sensitiveControllerUpdateDetails(
      body: SensitiveUpdatePayload,
      options: any = {},
    ): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling sensitiveControllerUpdateDetails.',
        );
      }
      const localVarPath = `/sensitive`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('Authorization')
            : configuration.apiKey;
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );
      const needsSerialization =
        'SensitiveUpdatePayload' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization
        ? JSON.stringify(body || {})
        : body || '';

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Download GDPR-compliant personal data archive
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountControllerExport(
      token: string,
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
      const localVarFetchArgs = DefaultApiFetchParamCreator(
        configuration,
      ).accountControllerExport(token, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Retrieve current account and associated data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountControllerGet(
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<Account> {
      const localVarFetchArgs =
        DefaultApiFetchParamCreator(configuration).accountControllerGet(
          options,
        );
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Start account deletion
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountControllerStartDeleting(
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
      const localVarFetchArgs =
        DefaultApiFetchParamCreator(
          configuration,
        ).accountControllerStartDeleting(options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Stop account deletion
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountControllerStopDeleting(
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
      const localVarFetchArgs =
        DefaultApiFetchParamCreator(
          configuration,
        ).accountControllerStopDeleting(options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Switch to associated account
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountControllerSwitch(
      id: string,
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<AccountSwitchResponse> {
      const localVarFetchArgs = DefaultApiFetchParamCreator(
        configuration,
      ).accountControllerSwitch(id, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Assign broker to account
     * @param {BrokerToAccountPayload} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerAssignBrokerToAccount(
      body: BrokerToAccountPayload,
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
      const localVarFetchArgs = DefaultApiFetchParamCreator(
        configuration,
      ).adminControllerAssignBrokerToAccount(body, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Change order payer
     * @param {ChangePayerPayload} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerChangePayer(
      body: ChangePayerPayload,
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<Order> {
      const localVarFetchArgs = DefaultApiFetchParamCreator(
        configuration,
      ).adminControllerChangePayer(body, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Delete broker
     * @param {Broker} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerDeleteBroker(
      body: Broker,
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
      const localVarFetchArgs = DefaultApiFetchParamCreator(
        configuration,
      ).adminControllerDeleteBroker(body, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Delete offer
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerDeleteOffer(
      id: string,
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
      const localVarFetchArgs = DefaultApiFetchParamCreator(
        configuration,
      ).adminControllerDeleteOffer(id, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Generate accounts report
     * @param {AccountsReportPayload} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerGenerateReport(
      body: AccountsReportPayload,
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<Array<AccountsReportRow>> {
      const localVarFetchArgs = DefaultApiFetchParamCreator(
        configuration,
      ).adminControllerGenerateReport(body, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Export accounts as MIS Life XML
     * @param {MislifePayload} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerGenerateReportMISLife(
      body: MislifePayload,
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
      const localVarFetchArgs = DefaultApiFetchParamCreator(
        configuration,
      ).adminControllerGenerateReportMISLife(body, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Export broker company accounts as MIS Life XML archive
     * @param {string} brokerCompanyId
     * @param {Array<string>} productTypes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerGenerateReportMISLifeArchive(
      brokerCompanyId: string,
      productTypes: Array<string>,
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
      const localVarFetchArgs = DefaultApiFetchParamCreator(
        configuration,
      ).adminControllerGenerateReportMISLifeArchive(
        brokerCompanyId,
        productTypes,
        options,
      );
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Export accounts report as XLS
     * @param {AccountsReportPayload} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerGenerateReportXLS(
      body: AccountsReportPayload,
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
      const localVarFetchArgs = DefaultApiFetchParamCreator(
        configuration,
      ).adminControllerGenerateReportXLS(body, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary List all accounts data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerGetAccounts(
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<Array<Account>> {
      const localVarFetchArgs =
        DefaultApiFetchParamCreator(configuration).adminControllerGetAccounts(
          options,
        );
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary List all brokers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerGetBrokers(
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<Array<Broker>> {
      const localVarFetchArgs =
        DefaultApiFetchParamCreator(configuration).adminControllerGetBrokers(
          options,
        );
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Get invite for brokers
     * @param {BrokerInvitePayload} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerGetBrokersInvite(
      body: BrokerInvitePayload,
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<BrokerInvite> {
      const localVarFetchArgs = DefaultApiFetchParamCreator(
        configuration,
      ).adminControllerGetBrokersInvite(body, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @param {MetricsPayload} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerGetMRR(
      body: MetricsPayload,
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<Array<BrokerMRR>> {
      const localVarFetchArgs = DefaultApiFetchParamCreator(
        configuration,
      ).adminControllerGetMRR(body, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Get offers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerGetOffers(
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<Array<Offer>> {
      const localVarFetchArgs =
        DefaultApiFetchParamCreator(configuration).adminControllerGetOffers(
          options,
        );
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Get all pricings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerGetPricings(
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<Array<Pricing>> {
      const localVarFetchArgs =
        DefaultApiFetchParamCreator(configuration).adminControllerGetPricings(
          options,
        );
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary List brokers and customers
     * @param {'health' | 'health_checkup'} [productType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerListBrokerCustomers(
      productType?: 'health' | 'health_checkup',
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<Array<BrokerCustomers>> {
      const localVarFetchArgs = DefaultApiFetchParamCreator(
        configuration,
      ).adminControllerListBrokerCustomers(productType, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Save broker
     * @param {Broker} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerSaveBroker(
      body: Broker,
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<Broker> {
      const localVarFetchArgs = DefaultApiFetchParamCreator(
        configuration,
      ).adminControllerSaveBroker(body, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Save offer
     * @param {Offer} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerSaveOffer(
      body: Offer,
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<Offer> {
      const localVarFetchArgs = DefaultApiFetchParamCreator(
        configuration,
      ).adminControllerSaveOffer(body, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Save pricing
     * @param {PricingPayload} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerSavePricing(
      body: PricingPayload,
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<Pricing> {
      const localVarFetchArgs = DefaultApiFetchParamCreator(
        configuration,
      ).adminControllerSavePricing(body, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Cancel BankID auth session
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authControllerCancel(
      id: string,
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<AuthRequest> {
      const localVarFetchArgs = DefaultApiFetchParamCreator(
        configuration,
      ).authControllerCancel(id, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Initiate BankID auth session
     * @param {AuthPayload} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authControllerStart(
      body: AuthPayload,
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<AuthRequest> {
      const localVarFetchArgs = DefaultApiFetchParamCreator(
        configuration,
      ).authControllerStart(body, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Verify status of BankID auth session
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authControllerVerify(
      id: string,
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<AuthRequest> {
      const localVarFetchArgs = DefaultApiFetchParamCreator(
        configuration,
      ).authControllerVerify(id, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Check status and list of bank accounts
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bankControllerCheckFetching(
      id: string,
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<BankRequest> {
      const localVarFetchArgs = DefaultApiFetchParamCreator(
        configuration,
      ).bankControllerCheckFetching(id, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Request auth to fetch bank accounts
     * @param {BankRequestPayload} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bankControllerStartFetching(
      body: BankRequestPayload,
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<BankRequest> {
      const localVarFetchArgs = DefaultApiFetchParamCreator(
        configuration,
      ).bankControllerStartFetching(body, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Register new broker
     * @param {BrokerPayload} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    brokerControllerCreateBroker(
      body: BrokerPayload,
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<Broker> {
      const localVarFetchArgs = DefaultApiFetchParamCreator(
        configuration,
      ).brokerControllerCreateBroker(body, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Check if insurance agreement generated
     * @param {string} id
     * @param {string} product
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    documentControllerCheckAgreement(
      id: string,
      product: string,
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
      const localVarFetchArgs = DefaultApiFetchParamCreator(
        configuration,
      ).documentControllerCheckAgreement(id, product, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Get insurance agreement for relevant account and product
     * @param {string} id
     * @param {string} related
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    documentControllerGetAgreement(
      id: string,
      related: string,
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
      const localVarFetchArgs = DefaultApiFetchParamCreator(
        configuration,
      ).documentControllerGetAgreement(id, related, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Get monthly report
     * @param {string} id
     * @param {string} related
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    documentControllerGetMonthlyReport(
      id: string,
      related: string,
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
      const localVarFetchArgs = DefaultApiFetchParamCreator(
        configuration,
      ).documentControllerGetMonthlyReport(id, related, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Get self-invoice PDF
     * @param {string} id
     * @param {string} related
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    documentControllerGetSelfInvoicePDF(
      id: string,
      related: string,
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
      const localVarFetchArgs = DefaultApiFetchParamCreator(
        configuration,
      ).documentControllerGetSelfInvoicePDF(id, related, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    eRemissControllerHandleNotification(
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
      const localVarFetchArgs =
        DefaultApiFetchParamCreator(
          configuration,
        ).eRemissControllerHandleNotification(options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    eRemissControllerHandlePostNotification(
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
      const localVarFetchArgs =
        DefaultApiFetchParamCreator(
          configuration,
        ).eRemissControllerHandlePostNotification(options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @param {ModelObject} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    giddirControllerHandlePostNotification(
      body: ModelObject,
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
      const localVarFetchArgs = DefaultApiFetchParamCreator(
        configuration,
      ).giddirControllerHandlePostNotification(body, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Add new person to order
     * @param {string} id
     * @param {Person} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    orderControllerAddPerson(
      id: string,
      body: Person,
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<Product> {
      const localVarFetchArgs = DefaultApiFetchParamCreator(
        configuration,
      ).orderControllerAddPerson(id, body, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Add new product to order
     * @param {string} id
     * @param {ProductPayload} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    orderControllerAddProduct(
      id: string,
      body: ProductPayload,
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<Product> {
      const localVarFetchArgs = DefaultApiFetchParamCreator(
        configuration,
      ).orderControllerAddProduct(id, body, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Cancel product
     * @param {string} id
     * @param {string} note
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    orderControllerCancelProduct(
      id: string,
      note: string,
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
      const localVarFetchArgs = DefaultApiFetchParamCreator(
        configuration,
      ).orderControllerCancelProduct(id, note, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Create new order for current account
     * @param {OrderOptionsPayload} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    orderControllerCreate(
      body: OrderOptionsPayload,
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<Order> {
      const localVarFetchArgs = DefaultApiFetchParamCreator(
        configuration,
      ).orderControllerCreate(body, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Get specific offer
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    orderControllerGetOffer(
      id: string,
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<OrderPayload> {
      const localVarFetchArgs = DefaultApiFetchParamCreator(
        configuration,
      ).orderControllerGetOffer(id, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Get latest pricing
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    orderControllerGetPricing(
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<Pricing> {
      const localVarFetchArgs =
        DefaultApiFetchParamCreator(configuration).orderControllerGetPricing(
          options,
        );
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Get specific pricing
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    orderControllerGetPricingById(
      id: string,
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<Pricing> {
      const localVarFetchArgs = DefaultApiFetchParamCreator(
        configuration,
      ).orderControllerGetPricingById(id, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Create new order for multiple products
     * @param {OrderPayload} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    orderControllerMultiCreate(
      body: OrderPayload,
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<Order> {
      const localVarFetchArgs = DefaultApiFetchParamCreator(
        configuration,
      ).orderControllerMultiCreate(body, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Preview order for multiple products
     * @param {OrderPayload} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    orderControllerMultiPreview(
      body: OrderPayload,
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<Order> {
      const localVarFetchArgs = DefaultApiFetchParamCreator(
        configuration,
      ).orderControllerMultiPreview(body, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Preview order for product
     * @param {string} productType
     * @param {number} paymentPeriod
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    orderControllerPreview(
      productType: string,
      paymentPeriod: number,
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<Order> {
      const localVarFetchArgs = DefaultApiFetchParamCreator(
        configuration,
      ).orderControllerPreview(productType, paymentPeriod, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Update order options
     * @param {string} id
     * @param {OrderOptionsPayload} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    orderControllerUpdateOrder(
      id: string,
      body: OrderOptionsPayload,
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
      const localVarFetchArgs = DefaultApiFetchParamCreator(
        configuration,
      ).orderControllerUpdateOrder(id, body, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Connect new payment method
     * @param {PaymentPayload} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentControllerConnectPaymentMethod(
      body: PaymentPayload,
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<ConnectPaymentResponse> {
      const localVarFetchArgs = DefaultApiFetchParamCreator(
        configuration,
      ).paymentControllerConnectPaymentMethod(body, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Toggle payment method
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentControllerTogglePaymentMethod(
      id: string,
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
      const localVarFetchArgs = DefaultApiFetchParamCreator(
        configuration,
      ).paymentControllerTogglePaymentMethod(id, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    productControllerGetLabs(
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<Array<Lab>> {
      const localVarFetchArgs =
        DefaultApiFetchParamCreator(configuration).productControllerGetLabs(
          options,
        );
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Get quiz created within 30 days
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    quizControllerGet(
      id: string,
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<Quiz> {
      const localVarFetchArgs = DefaultApiFetchParamCreator(
        configuration,
      ).quizControllerGet(id, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Invite customer to complete the health quiz
     * @param {string} id
     * @param {QuizInvite} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    quizControllerInvite(
      id: string,
      body: QuizInvite,
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
      const localVarFetchArgs = DefaultApiFetchParamCreator(
        configuration,
      ).quizControllerInvite(id, body, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Save quiz answers and get available product types
     * @param {boolean} complete
     * @param {Quiz} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    quizControllerSave(
      complete: boolean,
      body: Quiz,
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<Quiz> {
      const localVarFetchArgs = DefaultApiFetchParamCreator(
        configuration,
      ).quizControllerSave(complete, body, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Look up sensitive details
     * @param {SensitiveLookupPayload} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sensitiveControllerLookup(
      body: SensitiveLookupPayload,
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<Sensitive> {
      const localVarFetchArgs = DefaultApiFetchParamCreator(
        configuration,
      ).sensitiveControllerLookup(body, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Update sensitive details
     * @param {SensitiveUpdatePayload} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sensitiveControllerUpdateDetails(
      body: SensitiveUpdatePayload,
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
      const localVarFetchArgs = DefaultApiFetchParamCreator(
        configuration,
      ).sensitiveControllerUpdateDetails(body, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        });
      };
    },
  };
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (
  configuration?: Configuration,
  fetch?: FetchAPI,
  basePath?: string,
) {
  return {
    /**
     *
     * @summary Download GDPR-compliant personal data archive
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountControllerExport(token: string, options?: any) {
      return DefaultApiFp(configuration).accountControllerExport(
        token,
        options,
      )(fetch, basePath);
    },
    /**
     *
     * @summary Retrieve current account and associated data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountControllerGet(options?: any) {
      return DefaultApiFp(configuration).accountControllerGet(options)(
        fetch,
        basePath,
      );
    },
    /**
     *
     * @summary Start account deletion
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountControllerStartDeleting(options?: any) {
      return DefaultApiFp(configuration).accountControllerStartDeleting(
        options,
      )(fetch, basePath);
    },
    /**
     *
     * @summary Stop account deletion
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountControllerStopDeleting(options?: any) {
      return DefaultApiFp(configuration).accountControllerStopDeleting(options)(
        fetch,
        basePath,
      );
    },
    /**
     *
     * @summary Switch to associated account
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountControllerSwitch(id: string, options?: any) {
      return DefaultApiFp(configuration).accountControllerSwitch(id, options)(
        fetch,
        basePath,
      );
    },
    /**
     *
     * @summary Assign broker to account
     * @param {BrokerToAccountPayload} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerAssignBrokerToAccount(
      body: BrokerToAccountPayload,
      options?: any,
    ) {
      return DefaultApiFp(configuration).adminControllerAssignBrokerToAccount(
        body,
        options,
      )(fetch, basePath);
    },
    /**
     *
     * @summary Change order payer
     * @param {ChangePayerPayload} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerChangePayer(body: ChangePayerPayload, options?: any) {
      return DefaultApiFp(configuration).adminControllerChangePayer(
        body,
        options,
      )(fetch, basePath);
    },
    /**
     *
     * @summary Delete broker
     * @param {Broker} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerDeleteBroker(body: Broker, options?: any) {
      return DefaultApiFp(configuration).adminControllerDeleteBroker(
        body,
        options,
      )(fetch, basePath);
    },
    /**
     *
     * @summary Delete offer
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerDeleteOffer(id: string, options?: any) {
      return DefaultApiFp(configuration).adminControllerDeleteOffer(
        id,
        options,
      )(fetch, basePath);
    },
    /**
     *
     * @summary Generate accounts report
     * @param {AccountsReportPayload} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerGenerateReport(body: AccountsReportPayload, options?: any) {
      return DefaultApiFp(configuration).adminControllerGenerateReport(
        body,
        options,
      )(fetch, basePath);
    },
    /**
     *
     * @summary Export accounts as MIS Life XML
     * @param {MislifePayload} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerGenerateReportMISLife(body: MislifePayload, options?: any) {
      return DefaultApiFp(configuration).adminControllerGenerateReportMISLife(
        body,
        options,
      )(fetch, basePath);
    },
    /**
     *
     * @summary Export broker company accounts as MIS Life XML archive
     * @param {string} brokerCompanyId
     * @param {Array<string>} productTypes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerGenerateReportMISLifeArchive(
      brokerCompanyId: string,
      productTypes: Array<string>,
      options?: any,
    ) {
      return DefaultApiFp(
        configuration,
      ).adminControllerGenerateReportMISLifeArchive(
        brokerCompanyId,
        productTypes,
        options,
      )(fetch, basePath);
    },
    /**
     *
     * @summary Export accounts report as XLS
     * @param {AccountsReportPayload} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerGenerateReportXLS(
      body: AccountsReportPayload,
      options?: any,
    ) {
      return DefaultApiFp(configuration).adminControllerGenerateReportXLS(
        body,
        options,
      )(fetch, basePath);
    },
    /**
     *
     * @summary List all accounts data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerGetAccounts(options?: any) {
      return DefaultApiFp(configuration).adminControllerGetAccounts(options)(
        fetch,
        basePath,
      );
    },
    /**
     *
     * @summary List all brokers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerGetBrokers(options?: any) {
      return DefaultApiFp(configuration).adminControllerGetBrokers(options)(
        fetch,
        basePath,
      );
    },
    /**
     *
     * @summary Get invite for brokers
     * @param {BrokerInvitePayload} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerGetBrokersInvite(body: BrokerInvitePayload, options?: any) {
      return DefaultApiFp(configuration).adminControllerGetBrokersInvite(
        body,
        options,
      )(fetch, basePath);
    },
    /**
     *
     * @param {MetricsPayload} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerGetMRR(body: MetricsPayload, options?: any) {
      return DefaultApiFp(configuration).adminControllerGetMRR(body, options)(
        fetch,
        basePath,
      );
    },
    /**
     *
     * @summary Get offers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerGetOffers(options?: any) {
      return DefaultApiFp(configuration).adminControllerGetOffers(options)(
        fetch,
        basePath,
      );
    },
    /**
     *
     * @summary Get all pricings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerGetPricings(options?: any) {
      return DefaultApiFp(configuration).adminControllerGetPricings(options)(
        fetch,
        basePath,
      );
    },
    /**
     *
     * @summary List brokers and customers
     * @param {'health' | 'health_checkup'} [productType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerListBrokerCustomers(
      productType?: 'health' | 'health_checkup',
      options?: any,
    ) {
      return DefaultApiFp(configuration).adminControllerListBrokerCustomers(
        productType,
        options,
      )(fetch, basePath);
    },
    /**
     *
     * @summary Save broker
     * @param {Broker} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerSaveBroker(body: Broker, options?: any) {
      return DefaultApiFp(configuration).adminControllerSaveBroker(
        body,
        options,
      )(fetch, basePath);
    },
    /**
     *
     * @summary Save offer
     * @param {Offer} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerSaveOffer(body: Offer, options?: any) {
      return DefaultApiFp(configuration).adminControllerSaveOffer(
        body,
        options,
      )(fetch, basePath);
    },
    /**
     *
     * @summary Save pricing
     * @param {PricingPayload} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerSavePricing(body: PricingPayload, options?: any) {
      return DefaultApiFp(configuration).adminControllerSavePricing(
        body,
        options,
      )(fetch, basePath);
    },
    /**
     *
     * @summary Cancel BankID auth session
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authControllerCancel(id: string, options?: any) {
      return DefaultApiFp(configuration).authControllerCancel(id, options)(
        fetch,
        basePath,
      );
    },
    /**
     *
     * @summary Initiate BankID auth session
     * @param {AuthPayload} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authControllerStart(body: AuthPayload, options?: any) {
      return DefaultApiFp(configuration).authControllerStart(body, options)(
        fetch,
        basePath,
      );
    },
    /**
     *
     * @summary Verify status of BankID auth session
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authControllerVerify(id: string, options?: any) {
      return DefaultApiFp(configuration).authControllerVerify(id, options)(
        fetch,
        basePath,
      );
    },
    /**
     *
     * @summary Check status and list of bank accounts
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bankControllerCheckFetching(id: string, options?: any) {
      return DefaultApiFp(configuration).bankControllerCheckFetching(
        id,
        options,
      )(fetch, basePath);
    },
    /**
     *
     * @summary Request auth to fetch bank accounts
     * @param {BankRequestPayload} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bankControllerStartFetching(body: BankRequestPayload, options?: any) {
      return DefaultApiFp(configuration).bankControllerStartFetching(
        body,
        options,
      )(fetch, basePath);
    },
    /**
     *
     * @summary Register new broker
     * @param {BrokerPayload} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    brokerControllerCreateBroker(body: BrokerPayload, options?: any) {
      return DefaultApiFp(configuration).brokerControllerCreateBroker(
        body,
        options,
      )(fetch, basePath);
    },
    /**
     *
     * @summary Check if insurance agreement generated
     * @param {string} id
     * @param {string} product
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    documentControllerCheckAgreement(
      id: string,
      product: string,
      options?: any,
    ) {
      return DefaultApiFp(configuration).documentControllerCheckAgreement(
        id,
        product,
        options,
      )(fetch, basePath);
    },
    /**
     *
     * @summary Get insurance agreement for relevant account and product
     * @param {string} id
     * @param {string} related
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    documentControllerGetAgreement(id: string, related: string, options?: any) {
      return DefaultApiFp(configuration).documentControllerGetAgreement(
        id,
        related,
        options,
      )(fetch, basePath);
    },
    /**
     *
     * @summary Get monthly report
     * @param {string} id
     * @param {string} related
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    documentControllerGetMonthlyReport(
      id: string,
      related: string,
      options?: any,
    ) {
      return DefaultApiFp(configuration).documentControllerGetMonthlyReport(
        id,
        related,
        options,
      )(fetch, basePath);
    },
    /**
     *
     * @summary Get self-invoice PDF
     * @param {string} id
     * @param {string} related
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    documentControllerGetSelfInvoicePDF(
      id: string,
      related: string,
      options?: any,
    ) {
      return DefaultApiFp(configuration).documentControllerGetSelfInvoicePDF(
        id,
        related,
        options,
      )(fetch, basePath);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    eRemissControllerHandleNotification(options?: any) {
      return DefaultApiFp(configuration).eRemissControllerHandleNotification(
        options,
      )(fetch, basePath);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    eRemissControllerHandlePostNotification(options?: any) {
      return DefaultApiFp(
        configuration,
      ).eRemissControllerHandlePostNotification(options)(fetch, basePath);
    },
    /**
     *
     * @param {ModelObject} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    giddirControllerHandlePostNotification(body: ModelObject, options?: any) {
      return DefaultApiFp(configuration).giddirControllerHandlePostNotification(
        body,
        options,
      )(fetch, basePath);
    },
    /**
     *
     * @summary Add new person to order
     * @param {string} id
     * @param {Person} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    orderControllerAddPerson(id: string, body: Person, options?: any) {
      return DefaultApiFp(configuration).orderControllerAddPerson(
        id,
        body,
        options,
      )(fetch, basePath);
    },
    /**
     *
     * @summary Add new product to order
     * @param {string} id
     * @param {ProductPayload} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    orderControllerAddProduct(id: string, body: ProductPayload, options?: any) {
      return DefaultApiFp(configuration).orderControllerAddProduct(
        id,
        body,
        options,
      )(fetch, basePath);
    },
    /**
     *
     * @summary Cancel product
     * @param {string} id
     * @param {string} note
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    orderControllerCancelProduct(id: string, note: string, options?: any) {
      return DefaultApiFp(configuration).orderControllerCancelProduct(
        id,
        note,
        options,
      )(fetch, basePath);
    },
    /**
     *
     * @summary Create new order for current account
     * @param {OrderOptionsPayload} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    orderControllerCreate(body: OrderOptionsPayload, options?: any) {
      return DefaultApiFp(configuration).orderControllerCreate(body, options)(
        fetch,
        basePath,
      );
    },
    /**
     *
     * @summary Get specific offer
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    orderControllerGetOffer(id: string, options?: any) {
      return DefaultApiFp(configuration).orderControllerGetOffer(id, options)(
        fetch,
        basePath,
      );
    },
    /**
     *
     * @summary Get latest pricing
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    orderControllerGetPricing(options?: any) {
      return DefaultApiFp(configuration).orderControllerGetPricing(options)(
        fetch,
        basePath,
      );
    },
    /**
     *
     * @summary Get specific pricing
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    orderControllerGetPricingById(id: string, options?: any) {
      return DefaultApiFp(configuration).orderControllerGetPricingById(
        id,
        options,
      )(fetch, basePath);
    },
    /**
     *
     * @summary Create new order for multiple products
     * @param {OrderPayload} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    orderControllerMultiCreate(body: OrderPayload, options?: any) {
      return DefaultApiFp(configuration).orderControllerMultiCreate(
        body,
        options,
      )(fetch, basePath);
    },
    /**
     *
     * @summary Preview order for multiple products
     * @param {OrderPayload} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    orderControllerMultiPreview(body: OrderPayload, options?: any) {
      return DefaultApiFp(configuration).orderControllerMultiPreview(
        body,
        options,
      )(fetch, basePath);
    },
    /**
     *
     * @summary Preview order for product
     * @param {string} productType
     * @param {number} paymentPeriod
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    orderControllerPreview(
      productType: string,
      paymentPeriod: number,
      options?: any,
    ) {
      return DefaultApiFp(configuration).orderControllerPreview(
        productType,
        paymentPeriod,
        options,
      )(fetch, basePath);
    },
    /**
     *
     * @summary Update order options
     * @param {string} id
     * @param {OrderOptionsPayload} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    orderControllerUpdateOrder(
      id: string,
      body: OrderOptionsPayload,
      options?: any,
    ) {
      return DefaultApiFp(configuration).orderControllerUpdateOrder(
        id,
        body,
        options,
      )(fetch, basePath);
    },
    /**
     *
     * @summary Connect new payment method
     * @param {PaymentPayload} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentControllerConnectPaymentMethod(body: PaymentPayload, options?: any) {
      return DefaultApiFp(configuration).paymentControllerConnectPaymentMethod(
        body,
        options,
      )(fetch, basePath);
    },
    /**
     *
     * @summary Toggle payment method
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentControllerTogglePaymentMethod(id: string, options?: any) {
      return DefaultApiFp(configuration).paymentControllerTogglePaymentMethod(
        id,
        options,
      )(fetch, basePath);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    productControllerGetLabs(options?: any) {
      return DefaultApiFp(configuration).productControllerGetLabs(options)(
        fetch,
        basePath,
      );
    },
    /**
     *
     * @summary Get quiz created within 30 days
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    quizControllerGet(id: string, options?: any) {
      return DefaultApiFp(configuration).quizControllerGet(id, options)(
        fetch,
        basePath,
      );
    },
    /**
     *
     * @summary Invite customer to complete the health quiz
     * @param {string} id
     * @param {QuizInvite} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    quizControllerInvite(id: string, body: QuizInvite, options?: any) {
      return DefaultApiFp(configuration).quizControllerInvite(
        id,
        body,
        options,
      )(fetch, basePath);
    },
    /**
     *
     * @summary Save quiz answers and get available product types
     * @param {boolean} complete
     * @param {Quiz} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    quizControllerSave(complete: boolean, body: Quiz, options?: any) {
      return DefaultApiFp(configuration).quizControllerSave(
        complete,
        body,
        options,
      )(fetch, basePath);
    },
    /**
     *
     * @summary Look up sensitive details
     * @param {SensitiveLookupPayload} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sensitiveControllerLookup(body: SensitiveLookupPayload, options?: any) {
      return DefaultApiFp(configuration).sensitiveControllerLookup(
        body,
        options,
      )(fetch, basePath);
    },
    /**
     *
     * @summary Update sensitive details
     * @param {SensitiveUpdatePayload} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sensitiveControllerUpdateDetails(
      body: SensitiveUpdatePayload,
      options?: any,
    ) {
      return DefaultApiFp(configuration).sensitiveControllerUpdateDetails(
        body,
        options,
      )(fetch, basePath);
    },
  };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
  /**
   *
   * @summary Download GDPR-compliant personal data archive
   * @param {string} token
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public accountControllerExport(token: string, options?: any) {
    return DefaultApiFp(this.configuration).accountControllerExport(
      token,
      options,
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Retrieve current account and associated data
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public accountControllerGet(options?: any) {
    return DefaultApiFp(this.configuration).accountControllerGet(options)(
      this.fetch,
      this.basePath,
    );
  }

  /**
   *
   * @summary Start account deletion
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public accountControllerStartDeleting(options?: any) {
    return DefaultApiFp(this.configuration).accountControllerStartDeleting(
      options,
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Stop account deletion
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public accountControllerStopDeleting(options?: any) {
    return DefaultApiFp(this.configuration).accountControllerStopDeleting(
      options,
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Switch to associated account
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public accountControllerSwitch(id: string, options?: any) {
    return DefaultApiFp(this.configuration).accountControllerSwitch(
      id,
      options,
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Assign broker to account
   * @param {BrokerToAccountPayload} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public adminControllerAssignBrokerToAccount(
    body: BrokerToAccountPayload,
    options?: any,
  ) {
    return DefaultApiFp(
      this.configuration,
    ).adminControllerAssignBrokerToAccount(body, options)(
      this.fetch,
      this.basePath,
    );
  }

  /**
   *
   * @summary Change order payer
   * @param {ChangePayerPayload} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public adminControllerChangePayer(body: ChangePayerPayload, options?: any) {
    return DefaultApiFp(this.configuration).adminControllerChangePayer(
      body,
      options,
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Delete broker
   * @param {Broker} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public adminControllerDeleteBroker(body: Broker, options?: any) {
    return DefaultApiFp(this.configuration).adminControllerDeleteBroker(
      body,
      options,
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Delete offer
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public adminControllerDeleteOffer(id: string, options?: any) {
    return DefaultApiFp(this.configuration).adminControllerDeleteOffer(
      id,
      options,
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Generate accounts report
   * @param {AccountsReportPayload} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public adminControllerGenerateReport(
    body: AccountsReportPayload,
    options?: any,
  ) {
    return DefaultApiFp(this.configuration).adminControllerGenerateReport(
      body,
      options,
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Export accounts as MIS Life XML
   * @param {MislifePayload} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public adminControllerGenerateReportMISLife(
    body: MislifePayload,
    options?: any,
  ) {
    return DefaultApiFp(
      this.configuration,
    ).adminControllerGenerateReportMISLife(body, options)(
      this.fetch,
      this.basePath,
    );
  }

  /**
   *
   * @summary Export broker company accounts as MIS Life XML archive
   * @param {string} brokerCompanyId
   * @param {Array<string>} productTypes
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public adminControllerGenerateReportMISLifeArchive(
    brokerCompanyId: string,
    productTypes: Array<string>,
    options?: any,
  ) {
    return DefaultApiFp(
      this.configuration,
    ).adminControllerGenerateReportMISLifeArchive(
      brokerCompanyId,
      productTypes,
      options,
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Export accounts report as XLS
   * @param {AccountsReportPayload} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public adminControllerGenerateReportXLS(
    body: AccountsReportPayload,
    options?: any,
  ) {
    return DefaultApiFp(this.configuration).adminControllerGenerateReportXLS(
      body,
      options,
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary List all accounts data
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public adminControllerGetAccounts(options?: any) {
    return DefaultApiFp(this.configuration).adminControllerGetAccounts(options)(
      this.fetch,
      this.basePath,
    );
  }

  /**
   *
   * @summary List all brokers
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public adminControllerGetBrokers(options?: any) {
    return DefaultApiFp(this.configuration).adminControllerGetBrokers(options)(
      this.fetch,
      this.basePath,
    );
  }

  /**
   *
   * @summary Get invite for brokers
   * @param {BrokerInvitePayload} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public adminControllerGetBrokersInvite(
    body: BrokerInvitePayload,
    options?: any,
  ) {
    return DefaultApiFp(this.configuration).adminControllerGetBrokersInvite(
      body,
      options,
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @param {MetricsPayload} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public adminControllerGetMRR(body: MetricsPayload, options?: any) {
    return DefaultApiFp(this.configuration).adminControllerGetMRR(
      body,
      options,
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Get offers
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public adminControllerGetOffers(options?: any) {
    return DefaultApiFp(this.configuration).adminControllerGetOffers(options)(
      this.fetch,
      this.basePath,
    );
  }

  /**
   *
   * @summary Get all pricings
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public adminControllerGetPricings(options?: any) {
    return DefaultApiFp(this.configuration).adminControllerGetPricings(options)(
      this.fetch,
      this.basePath,
    );
  }

  /**
   *
   * @summary List brokers and customers
   * @param {'health' | 'health_checkup'} [productType]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public adminControllerListBrokerCustomers(
    productType?: 'health' | 'health_checkup',
    options?: any,
  ) {
    return DefaultApiFp(this.configuration).adminControllerListBrokerCustomers(
      productType,
      options,
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Save broker
   * @param {Broker} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public adminControllerSaveBroker(body: Broker, options?: any) {
    return DefaultApiFp(this.configuration).adminControllerSaveBroker(
      body,
      options,
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Save offer
   * @param {Offer} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public adminControllerSaveOffer(body: Offer, options?: any) {
    return DefaultApiFp(this.configuration).adminControllerSaveOffer(
      body,
      options,
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Save pricing
   * @param {PricingPayload} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public adminControllerSavePricing(body: PricingPayload, options?: any) {
    return DefaultApiFp(this.configuration).adminControllerSavePricing(
      body,
      options,
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Cancel BankID auth session
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public authControllerCancel(id: string, options?: any) {
    return DefaultApiFp(this.configuration).authControllerCancel(id, options)(
      this.fetch,
      this.basePath,
    );
  }

  /**
   *
   * @summary Initiate BankID auth session
   * @param {AuthPayload} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public authControllerStart(body: AuthPayload, options?: any) {
    return DefaultApiFp(this.configuration).authControllerStart(body, options)(
      this.fetch,
      this.basePath,
    );
  }

  /**
   *
   * @summary Verify status of BankID auth session
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public authControllerVerify(id: string, options?: any) {
    return DefaultApiFp(this.configuration).authControllerVerify(id, options)(
      this.fetch,
      this.basePath,
    );
  }

  /**
   *
   * @summary Check status and list of bank accounts
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public bankControllerCheckFetching(id: string, options?: any) {
    return DefaultApiFp(this.configuration).bankControllerCheckFetching(
      id,
      options,
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Request auth to fetch bank accounts
   * @param {BankRequestPayload} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public bankControllerStartFetching(body: BankRequestPayload, options?: any) {
    return DefaultApiFp(this.configuration).bankControllerStartFetching(
      body,
      options,
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Register new broker
   * @param {BrokerPayload} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public brokerControllerCreateBroker(body: BrokerPayload, options?: any) {
    return DefaultApiFp(this.configuration).brokerControllerCreateBroker(
      body,
      options,
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Check if insurance agreement generated
   * @param {string} id
   * @param {string} product
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public documentControllerCheckAgreement(
    id: string,
    product: string,
    options?: any,
  ) {
    return DefaultApiFp(this.configuration).documentControllerCheckAgreement(
      id,
      product,
      options,
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Get insurance agreement for relevant account and product
   * @param {string} id
   * @param {string} related
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public documentControllerGetAgreement(
    id: string,
    related: string,
    options?: any,
  ) {
    return DefaultApiFp(this.configuration).documentControllerGetAgreement(
      id,
      related,
      options,
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Get monthly report
   * @param {string} id
   * @param {string} related
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public documentControllerGetMonthlyReport(
    id: string,
    related: string,
    options?: any,
  ) {
    return DefaultApiFp(this.configuration).documentControllerGetMonthlyReport(
      id,
      related,
      options,
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Get self-invoice PDF
   * @param {string} id
   * @param {string} related
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public documentControllerGetSelfInvoicePDF(
    id: string,
    related: string,
    options?: any,
  ) {
    return DefaultApiFp(this.configuration).documentControllerGetSelfInvoicePDF(
      id,
      related,
      options,
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public eRemissControllerHandleNotification(options?: any) {
    return DefaultApiFp(this.configuration).eRemissControllerHandleNotification(
      options,
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public eRemissControllerHandlePostNotification(options?: any) {
    return DefaultApiFp(
      this.configuration,
    ).eRemissControllerHandlePostNotification(options)(
      this.fetch,
      this.basePath,
    );
  }

  /**
   *
   * @param {ModelObject} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public giddirControllerHandlePostNotification(
    body: ModelObject,
    options?: any,
  ) {
    return DefaultApiFp(
      this.configuration,
    ).giddirControllerHandlePostNotification(body, options)(
      this.fetch,
      this.basePath,
    );
  }

  /**
   *
   * @summary Add new person to order
   * @param {string} id
   * @param {Person} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public orderControllerAddPerson(id: string, body: Person, options?: any) {
    return DefaultApiFp(this.configuration).orderControllerAddPerson(
      id,
      body,
      options,
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Add new product to order
   * @param {string} id
   * @param {ProductPayload} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public orderControllerAddProduct(
    id: string,
    body: ProductPayload,
    options?: any,
  ) {
    return DefaultApiFp(this.configuration).orderControllerAddProduct(
      id,
      body,
      options,
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Cancel product
   * @param {string} id
   * @param {string} note
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public orderControllerCancelProduct(id: string, note: string, options?: any) {
    return DefaultApiFp(this.configuration).orderControllerCancelProduct(
      id,
      note,
      options,
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Create new order for current account
   * @param {OrderOptionsPayload} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public orderControllerCreate(body: OrderOptionsPayload, options?: any) {
    return DefaultApiFp(this.configuration).orderControllerCreate(
      body,
      options,
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Get specific offer
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public orderControllerGetOffer(id: string, options?: any) {
    return DefaultApiFp(this.configuration).orderControllerGetOffer(
      id,
      options,
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Get latest pricing
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public orderControllerGetPricing(options?: any) {
    return DefaultApiFp(this.configuration).orderControllerGetPricing(options)(
      this.fetch,
      this.basePath,
    );
  }

  /**
   *
   * @summary Get specific pricing
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public orderControllerGetPricingById(id: string, options?: any) {
    return DefaultApiFp(this.configuration).orderControllerGetPricingById(
      id,
      options,
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Create new order for multiple products
   * @param {OrderPayload} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public orderControllerMultiCreate(body: OrderPayload, options?: any) {
    return DefaultApiFp(this.configuration).orderControllerMultiCreate(
      body,
      options,
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Preview order for multiple products
   * @param {OrderPayload} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public orderControllerMultiPreview(body: OrderPayload, options?: any) {
    return DefaultApiFp(this.configuration).orderControllerMultiPreview(
      body,
      options,
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Preview order for product
   * @param {string} productType
   * @param {number} paymentPeriod
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public orderControllerPreview(
    productType: string,
    paymentPeriod: number,
    options?: any,
  ) {
    return DefaultApiFp(this.configuration).orderControllerPreview(
      productType,
      paymentPeriod,
      options,
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Update order options
   * @param {string} id
   * @param {OrderOptionsPayload} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public orderControllerUpdateOrder(
    id: string,
    body: OrderOptionsPayload,
    options?: any,
  ) {
    return DefaultApiFp(this.configuration).orderControllerUpdateOrder(
      id,
      body,
      options,
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Connect new payment method
   * @param {PaymentPayload} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public paymentControllerConnectPaymentMethod(
    body: PaymentPayload,
    options?: any,
  ) {
    return DefaultApiFp(
      this.configuration,
    ).paymentControllerConnectPaymentMethod(body, options)(
      this.fetch,
      this.basePath,
    );
  }

  /**
   *
   * @summary Toggle payment method
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public paymentControllerTogglePaymentMethod(id: string, options?: any) {
    return DefaultApiFp(
      this.configuration,
    ).paymentControllerTogglePaymentMethod(id, options)(
      this.fetch,
      this.basePath,
    );
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public productControllerGetLabs(options?: any) {
    return DefaultApiFp(this.configuration).productControllerGetLabs(options)(
      this.fetch,
      this.basePath,
    );
  }

  /**
   *
   * @summary Get quiz created within 30 days
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public quizControllerGet(id: string, options?: any) {
    return DefaultApiFp(this.configuration).quizControllerGet(id, options)(
      this.fetch,
      this.basePath,
    );
  }

  /**
   *
   * @summary Invite customer to complete the health quiz
   * @param {string} id
   * @param {QuizInvite} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public quizControllerInvite(id: string, body: QuizInvite, options?: any) {
    return DefaultApiFp(this.configuration).quizControllerInvite(
      id,
      body,
      options,
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Save quiz answers and get available product types
   * @param {boolean} complete
   * @param {Quiz} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public quizControllerSave(complete: boolean, body: Quiz, options?: any) {
    return DefaultApiFp(this.configuration).quizControllerSave(
      complete,
      body,
      options,
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Look up sensitive details
   * @param {SensitiveLookupPayload} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public sensitiveControllerLookup(
    body: SensitiveLookupPayload,
    options?: any,
  ) {
    return DefaultApiFp(this.configuration).sensitiveControllerLookup(
      body,
      options,
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Update sensitive details
   * @param {SensitiveUpdatePayload} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public sensitiveControllerUpdateDetails(
    body: SensitiveUpdatePayload,
    options?: any,
  ) {
    return DefaultApiFp(this.configuration).sensitiveControllerUpdateDetails(
      body,
      options,
    )(this.fetch, this.basePath);
  }
}
