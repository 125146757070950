import Box from '@material-ui/core/Box';
import React from 'react';
import { GoogleMap } from 'react-google-maps';
import useAsync from 'react-use/lib/useAsync';
import { googleGeolocationURL, googleMapsURL } from '../config';
import { Account } from '../hooks/useAccount';
import { LabsMap, labsMapStyles } from './LabsMap';
import { Markdown } from './Markdown';
import { useIntl } from 'react-intl';

type Props = { onLoad: () => void };

export const OrderCheckupInfo: React.FC<Props> = ({ onLoad }) => {
  const { orderContainer, loading, map } = labsMapStyles();
  const intl = useIntl();
  const { account } = Account.useContainer();
  const [googleMap, setGoogleMap] = React.useState<GoogleMap | undefined>(
    undefined,
  );
  const address =
    account?.sensitive?.streetAddress &&
    `${account.sensitive.streetAddress}, ${account.sensitive.postalCode} ${account.sensitive.locality}`;
  const [location, setLocation] = React.useState<
    google.maps.LatLng | undefined
  >(undefined);
  const geocoder = React.useMemo(() => {
    const Geocoder = window.google?.maps?.Geocoder;
    if (!googleMap || !Geocoder) return;
    return new Geocoder();
  }, [googleMap]);

  useAsync(async () => {
    if (geocoder && address) {
      const {
        results: [topResult],
      } = await geocoder.geocode({
        address,
        region: 'se',
      });

      if (topResult) {
        setLocation(topResult.geometry.location);
      } else {
        const response = await window.fetch(googleGeolocationURL, {
          method: 'post',
        });
        const geolocation = (await response.json()) as {
          location?: { lat: number; lng: number };
        };

        if (geolocation?.location) {
          setLocation(new google.maps.LatLng(geolocation.location));
        }
      }
    }
  }, [geocoder, address]);

  return (
    <Box pt={2}>
      <Markdown>checkup.how.order</Markdown>
      <LabsMap
        gotoLabText={intl.messages['goto_lab']?.toString() || 'goto_lab'}
        location={location}
        onLoad={(m) => {
          setGoogleMap(m);
          onLoad();
        }}
        googleMapURL={googleMapsURL}
        containerElement={<div className={orderContainer} />}
        loadingElement={<div className={loading} />}
        mapElement={<div className={map} />}
      />
    </Box>
  );
};
