// tslint:disable
/**
 * Unika API
 * Insurance management API
 *
 * OpenAPI spec version: 2025-01-19 12:12
 * Contact: kundservice@unikaforsakringar.se
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export * from './api';
export * from './configuration';
