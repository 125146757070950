import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import React from 'react';
import {
  Circle,
  GoogleMap,
  InfoWindow,
  Marker,
  withGoogleMap,
  withScriptjs,
} from 'react-google-maps';
import useAsync from 'react-use/lib/useAsync';
import { blue, Domine, Lato, yellow } from '../styles';
import { theme } from '../styles/theme';
import { Lab } from '../swagger';
import { api } from '../utils/api';
import LatLng = google.maps.LatLng;
import LatLngLiteral = google.maps.LatLngLiteral;

export const labsMapStyles = makeStyles({
  landingContainer: {
    height: `70vh`,
    margin: theme.spacing(6, -1),
    borderRadius: theme.spacing(1),
    overflow: 'hidden',

    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(6, -2),
    },
  },

  orderContainer: {
    height: `70vh`,
    borderRadius: theme.spacing(1),
    overflow: 'hidden',
  },

  loading: {
    height: '100%',
  },

  map: {
    height: '100%',
  },

  info: {
    fontFamily: Lato,
    fontSize: '15px',
    fontWeight: 'normal',
    color: theme.palette.text.primary,

    '& h2': {
      fontFamily: Domine,
      color: blue,
    },
    '& h3': {
      fontFamily: Domine,
      color: blue,
    },
    '& p:empty': {
      display: 'none',
    },
  },
});

const googleMapsOptions: google.maps.MapOptions = {
  styles: [
    {
      featureType: 'poi.attraction',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'poi.business',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'poi.place_of_worship',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'poi.school',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'poi.sports_complex',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
  ],
  streetViewControl: false,
};

type Props = React.PropsWithChildren<{
  location?: LatLng;
  onLoad?: (map: GoogleMap) => void;
  gotoLabText: string;
}>;

const haversine_distance = (p1: LatLngLiteral, p2: LatLngLiteral) => {
  const R = 3958.8; // Radius of the Earth in miles
  const rlat1 = p1.lat * (Math.PI / 180); // Convert degrees to radians
  const rlat2 = p2.lat * (Math.PI / 180); // Convert degrees to radians
  const difflat = rlat2 - rlat1; // Radian difference (latitudes)
  const difflon = (p2.lng - p1.lng) * (Math.PI / 180); // Radian difference (longitudes)

  const d =
    2 *
    R *
    Math.asin(
      Math.sqrt(
        Math.sin(difflat / 2) * Math.sin(difflat / 2) +
          Math.cos(rlat1) *
            Math.cos(rlat2) *
            Math.sin(difflon / 2) *
            Math.sin(difflon / 2),
      ),
    );
  return d;
};

export const LabsMap = withScriptjs(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  withGoogleMap((props: Props) => {
    const { info } = labsMapStyles();
    const { location, onLoad } = props;
    const [activeLab, setActiveLab] = React.useState<Lab | undefined>();
    const [googleMap, setGoogleMap] = React.useState<GoogleMap | undefined>(
      undefined,
    );

    const { value: labs } = useAsync(() => api.productControllerGetLabs(), []);
    const bounds = React.useMemo(() => {
      if (!labs) {
        return undefined;
      }

      const mapBounds = new google.maps.LatLngBounds();

      if (!location) {
        labs.forEach((lab) => mapBounds.extend(lab));
      } else {
        mapBounds.extend(location);
        const locationLiteral = {
          lat: location.lat(),
          lng: location.lng(),
        };

        [...labs]
          .sort((a, b) => {
            const distanceA = haversine_distance(locationLiteral, a);
            const distanceB = haversine_distance(locationLiteral, b);

            return distanceA - distanceB;
          })
          .slice(0, 3)
          .forEach((lab) => mapBounds.extend(lab));
      }

      return mapBounds;
    }, [labs, location]);

    const setMap = React.useCallback(
      (map: GoogleMap | null) => {
        if (map) {
          setGoogleMap(map);
          onLoad?.(map);
        }
      },
      [onLoad],
    );

    React.useEffect(() => {
      if (googleMap && bounds) {
        googleMap.fitBounds(bounds);
      }
    }, [bounds, googleMap]);

    console.log(location);

    return (
      <GoogleMap ref={setMap} options={googleMapsOptions}>
        {location && (
          <Circle
            center={location}
            radius={32}
            options={{
              strokeWeight: 0,
              fillOpacity: 1,
              fillColor: yellow,
              clickable: false,
            }}
          />
        )}
        {labs?.map((lab) => (
          <Marker
            key={lab.id}
            title={lab.name}
            position={{ lat: lab.lat, lng: lab.lng }}
            onClick={() =>
              setActiveLab(activeLab?.id === lab.id ? undefined : lab)
            }
          >
            {activeLab?.id === lab.id && (
              <InfoWindow onCloseClick={() => setActiveLab(undefined)}>
                <div className={info}>
                  <h2 dangerouslySetInnerHTML={{ __html: lab.name }} />
                  {!!lab.address && <h3>{lab.address}</h3>}
                  <Button
                    href={lab.url}
                    variant="contained"
                    size="medium"
                    color="primary"
                    target="_blank"
                  >
                    {props.gotoLabText}
                  </Button>
                </div>
              </InfoWindow>
            )}
          </Marker>
        ))}
      </GoogleMap>
    );
  }),
);
