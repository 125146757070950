import Bugsnag from '@bugsnag/js';
import { useEffect, useState } from 'react';
import { createContainer } from 'unstated-next';
import { AuthPayload, AuthRequest } from '../swagger';
import { api } from '../utils/api';
import { DrawerContainer } from './useDrawer';
import { useRouter } from './useRouter';
import { useRoutes } from './useRoutes';
import { Settings } from './useSettings';

let pollTimer = 0;

const useAuth = () => {
  const { history } = useRouter();
  const { mina, insurance, checkup, quiz } = useRoutes();
  const { settings, update } = Settings.useContainer();
  const { toggleDrawer } = DrawerContainer.useContainer();
  const [hint, setHint] = useState<AuthRequest.HintEnum | undefined>();
  const [status, setStatus] = useState<AuthRequest.StatusEnum | undefined>();

  const startAuth = async (authReq: AuthPayload) => {
    setHint(AuthRequest.HintEnum.OutstandingTransaction);
    setStatus(AuthRequest.StatusEnum.Pending);

    try {
      console.info('startAuth');
      const authRes = await api.authControllerStart(authReq);
      update({
        auth: {
          id: authRes.id,
          qr: authRes.qr,
          autoStartToken: authRes.autoStartToken,
        },
      });
      toggleDrawer(true);
    } catch (e) {
      setHint(undefined);
      setStatus(AuthRequest.StatusEnum.Failed);
      console.error(e);
      Bugsnag.notify(e as unknown as Error);
    }
  };

  const resetAuth = () => {
    setHint(undefined);
    setStatus(undefined);
    update({ auth: undefined });
  };

  const cancelAuth = async () => {
    if (settings.auth) {
      void api.authControllerCancel(settings.auth.id);
      resetAuth();
    }
  };

  const pollAuth = async () => {
    clearTimeout(pollTimer);

    try {
      if (settings.auth) {
        const authRes = await api.authControllerVerify(settings.auth.id);

        setHint(authRes.hint);
        setStatus(authRes.status);

        if (authRes.status === AuthRequest.StatusEnum.Pending) {
          update({
            auth: {
              id: authRes.id,
              qr: authRes.qr,
              autoStartToken: authRes.autoStartToken,
            },
          });
        } else if (authRes.status === AuthRequest.StatusEnum.Complete) {
          update({
            token: authRes.token,
            auth: undefined,
          });

          resetAuth();
          toggleDrawer(false);

          if (
            !history.location.pathname.startsWith(mina) &&
            !history.location.pathname.startsWith(quiz) &&
            !history.location.pathname.startsWith(insurance) &&
            !history.location.pathname.startsWith(checkup)
          ) {
            history.push(mina);
          }
        }
        if (authRes.status === AuthRequest.StatusEnum.Failed) {
          return undefined;
        }
      }
    } catch (e) {
      console.error(e);
      Bugsnag.notify(e as unknown as Error);
    }

    pollTimer = window.setTimeout(pollAuth, 1000);
  };

  useEffect(() => {
    void pollAuth();
    return () => window.clearTimeout(pollTimer);
  }, [settings.auth?.id, settings.token]);

  return {
    status,
    hint,
    startAuth,
    cancelAuth,
    resetAuth,
  };
};

export const Auth = createContainer(useAuth);
