import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';
import React from 'react';
import { theme } from '../styles/theme';
import { DOC } from '../utils/api';

const styles = makeStyles<typeof theme, { fullWidth: boolean }>({
  stretch: {
    position: 'relative',
    width: '1280px',
    maxWidth: '100%',
  },
  frame: {
    display: 'block',
    width: '100%',
    border: '0 none',
    height: 'calc(100vh -  (8px * 18))',
    position: 'relative',
    background: 'transparent',
  },
  spinner: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    marginLeft: '-24px',
    marginTop: '-24px',
  },
});

let timer: NodeJS.Timeout;
interface DocumentProps {
  path: string;
  fullWidth?: false;
}

export const Document: React.FC<DocumentProps> = (props) => {
  const { path, fullWidth = true } = props;
  const { stretch, frame, spinner } = styles({ fullWidth });
  const [loading, setLoading] = React.useState<boolean | undefined>(undefined);
  const [r, setR] = React.useState(Date.now().toString());

  const url = new URL(path, DOC);
  url.searchParams.set('r', r);
  const src = url.toString();

  React.useEffect(() => {
    setLoading(true);

    timer = setInterval(() => {
      console.log('Retry iframe load');
      setR(Date.now().toString());
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, [src]);

  const onLoad = React.useCallback(() => {
    console.log('Iframe loaded');
    clearTimeout(timer);
    setLoading(false);
  }, []);

  console.log(`Loading document from ${src}`);

  return (
    <Box className={stretch}>
      {loading && (
        <CircularProgress
          variant="indeterminate"
          color="secondary"
          size={48}
          className={spinner}
        />
      )}
      <iframe src={src} className={frame} onLoad={onLoad} />
    </Box>
  );
};
